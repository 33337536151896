export const translations = {
  novaTransportadora: {
    pt: 'Nova Transportadora',
    en: 'New Carrier',
    de: 'Neuer Spediteur',
    es: 'Nuevo transportista',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
};