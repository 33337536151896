import { call, put, all } from "redux-saga/effects";

import { apiGeneral, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { AuthActions, ImpersonateActions } from "store/ducks/auth";

export function* impersonateRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(
      apiGeneral.post,
      `/impersonate`,
      postData
    );

    const { token: accessToken, ...data } = responseBody;

    apiGeneral.defaults.headers.Authorization = `Bearer ${accessToken}`;

    yield all([
      put(AuthActions.loginSuccess({ ...data, accessToken })),
      put(ImpersonateActions.success()),
      
    ]);

    onSuccess && onSuccess();
    notify("success", `Você se logou como ${data?.name}`);
    if (onSuccess) onSuccess();
    
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ImpersonateActions.failure(errorMessage));
  }
}
