export const translations = {
    configuracoes: {
        pt: 'Configurações',
        en: 'Settings',
        de: 'Einstellungen',
        es: 'Configuración',
      },
      editarTipo: {
        pt: 'Editar Tipo de Palete',
        en: 'Edit Pallet Type',
        de: 'Palettentyp bearbeiten',
        es: 'Editar tipo de Palet',
      },
      novoTipo: {
        pt: 'Novo Tipo de Palete',
        en: 'New Pallet Type',
        de: 'Neuer Palettentyp',
        es: 'Nuevo tipo de palet',
      },
};