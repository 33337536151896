import styled from "styled-components";
import { RadioButtonOff, RadioButtonOn } from "@styled-icons/evaicons-solid";
import { fonts, colorScheme } from "styles";

export {
  FieldContainer,
  FieldError,
  FieldLabel,
} from "styles/styled-components";

export const OptionsContainer = styled.div`
  display: flex;  
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
`;

export const Option = styled.label`
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  height: 100%;
  
  font-family: ${fonts.GilroyRegular};
`;

export const Icons = styled.div.attrs({ className: "icons" })``;

export const Input = styled.input`
  appearance: none;
  &:checked + .icons > .checked {
    display: initial;
  }
  &:not(:checked) + .icons > .unchecked {
    display: initial;
  }
`;

export const CheckedIcon = styled(RadioButtonOn).attrs({
  size: 24,
  className: "checked",
})`
  color: ${colorScheme.primary};
  margin-right: 10px;
  display: none;
`;

export const UncheckedIcon = styled(RadioButtonOff).attrs({
  size: 24,
  className: "unchecked",
})`
  color: #606060;
  margin-right: 10px;
  display: none;
`;
