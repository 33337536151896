export const translations = {
  assunto: {
    pt: 'Assunto',
    en: 'Subject matter',
    de: 'Betreff',
    es: 'Tema en cuestion',
  },
  destinatarios: {
    pt: 'Destinatários',
    en: 'Recipients',
    de: 'Empfänger',
    es: 'Destinatarios',
  },
  date: {
    pt: 'Data',
    en: 'Date',
    de: 'Datum',
    es: 'Fecha',
  },
};