import styled, { css } from 'styled-components';
import {
  Map,
  TimeFive,
  CheckSquare,
  BarChartAlt2,
  PieChartAlt2,
} from '@styled-icons/boxicons-solid';
import { MenuAltRight } from '@styled-icons/boxicons-regular';
import { Gumroad } from '@styled-icons/simple-icons/';
import logo from 'assets/images/logo-menu.png';
import { colors, fonts } from 'styles';

interface IButtonModule {
  active: boolean;
}

export const Container = styled.aside`
  width: 100%;
  max-width: 264px;
  height: 100%;
  min-height: 100vh;
  background-color: ${colors.white};
  padding: 40px;
  position: relative;
  z-index: 1;
  box-shadow: 5px 0 30px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  overflow-y: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  span {
    display: flex;
    width: 24px;
  }
`;

export const Logo = styled.img.attrs({ src: logo })`
  width: 100%;
  max-width: max-content;
`;

export const ButtonModule = styled.button.attrs({
  type: 'button',
})<IButtonModule>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  background-color: transparent;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;

export const IconTracking = styled(Map).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const IconSchedule = styled(TimeFive).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const IconRouter = styled(Gumroad).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const IconCheckFreight = styled(CheckSquare).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const IconSettings = styled(MenuAltRight).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const IconControlTower = styled(BarChartAlt2).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const IconReport = styled(PieChartAlt2).attrs({ size: 16 })`
  margin-right: 8px;
`;

export const ModuleTitle = styled.h3<{ isHomolog: boolean }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  text-align: left;
  margin-bottom: 4px;
  color: ${({ isHomolog }) =>
    isHomolog ? colors.warmGray10 : colors.deepPurple};
`;

export const ModuleDescription = styled.p`
  font-family: ${fonts.OpenSans};
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: ${colors.dark};
  opacity: 0.8;
`;

type SubMenuModuleProps = {
  active: boolean;
};

export const SubMenuModule = styled.div<SubMenuModuleProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  transition: 300ms ease;
  width: 100%;
  margin-top: 16px;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0;
      display: none;
    `};
`;

export const SubMenuModuleItem = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  background-color: transparent;
  transition: 300ms ease;
  font-family: ${fonts.GilroySemiBold};
  letter-spacing: 0.01em;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.darkRgba25};
  }

  &:hover {
    color: ${colors.deepPurple};
  }
`;
