export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Mandatory',
    es: 'Obligatorio',
  },
  novoCategoria: {
    pt: 'Nova categoria de documentos',
    en: 'New document category',
    de: 'Neue Dokumentenkategorie',
    es: 'NNueva categoría de documento',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Speichern',
    es: 'Registrar',
  },
  
};