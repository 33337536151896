export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  editarPermissao: {
    pt: "Editar Permissão",
    en: "Edit Permission",
    de: "Berechtigung bearbeiten",
    es: "Editar Permiso",
  },
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Name",
    es: "Nombre",
  },
  modulo: {
    pt: "Módulo",
    en: "Module",
    de: "Modul",
    es: "Módulo",
  },
  grupo: {
    pt: "Grupo",
    en: "Group",
    de: "Gruppe",
    es: "Grupo",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
  permissaoPorPais: {
    pt: "Permissão por País",
    en: "Permission by Country",
    de: "Berechtigung nach Land",
    es: "Permiso por País",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Löschen",
    es: "Retirar",
  },
  salvar: {
    pt: "Salvar",
    en: "Save",
    de: "Speichern",
    es: "Ahorrar",
  },
  removerTitulo: {
    pt: "Remover Perfil de Usuário",
    en: "Remove User Profile",
    de: "Benutzerprofil entfernen",
    es: "Eliminar perfil de usuario",
  },
  removerDesc: {
    pt: "Deseja realmente remover esse perfil?",
    en: "Do you really want to remove this profile?",
    de: "Möchten Sie dieses Profil wirklich entfernen?",
    es: "¿Realmente deseas eliminar este perfil?",
  },
  paises: {
    pt: "Países",
    en: "Countries",
    de: "Länder",
    es: "Países",
  },
};
