export const translations = {
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'id',
    es: 'Id',
  },
  segmentacao: {
    pt: 'Segmentação de clientes',
    en: 'customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
};