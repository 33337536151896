export const translations = {
  codCentro: {
    pt: 'cod. Centro',
    en: 'cod. Center',
    de: 'Werk-Code',
    es: 'cod. Centro',
  },
  descCentro: {
    pt: 'Desc. Centro',
    en: 'Desc. Center',
    de: 'Beschr. Werk',
    es: 'Desc. Centrar',
  },
  deletar: {
    pt: 'Deletar',
    en: 'Delete',
    de: 'Löschen',
    es: 'Eliminar',
  }, transportadora: {
    pt: "Transportadora",
    en: "Carrier",
    de: "Spediteur",
    es: "Transportadora",
  },
  tipo: {
    pt: "Tipo",
    en: "Type",
    de: "Typ",
    es: "Escribe",
  },
  origem: {
    pt: "Origem",
    en: "Origin",
    de: "Herkunft",
    es: "Origen",
  },
  destino: {
    pt: "Destino",
    en: "Destiny",
    de: "Ziel",
    es: "Destino",
  },
  kmInicial: {
    pt: "KM Inicial",
    en: "Initial KM",
    de: "Km Beginn",
    es: "KM inicial",
  },
  kmFinal: {
    pt: "KM Final",
    en: "Final KM",
    de: "Km Ende",
    es: "Schluss-KM",
  },
  prazoLotacao: {
    pt: "Prazo para Lotação",
    en: "Term for Capacity",
    de: "Laufzeit für die Vollladung",
    es: "Plazo de Capacidad",
  },
  prazoFracionado: {
    pt: "Prazo para Fracionado",
    en: "Term for Fractionated",
    de: "Laufzeit für Teilladung",
    es: "Término para Fraccionado",
  },
  peso: {
    pt: "Peso",
    en: "Weight",
    de: "Gewicht",
    es: "Peso",
  },
  cnpj: {
    pt: "CNPJ",
    en: "CNPJ",
    de: "CNPJ",
    es: 'Numero de Identificacion',
  },
};