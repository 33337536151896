export const translations = {
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  deletar: {
    pt: 'Deletar',
    en: 'Delete',
    de: 'Löschen',
    es: 'Borrar',
  },
  email: {
    pt: 'E-mail',
    en: 'E-mail',
    de: 'E-mail',
    es: 'Correo electrónico',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
  tipo: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Typ',
    es: 'Escribe',
  },
};