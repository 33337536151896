import styled, { css } from 'styled-components';
import { ChevronRightCircle } from '@styled-icons/boxicons-solid';
import { fonts, colors } from 'styles';

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
`;

const gridTemplateColumns = css`
  grid-template-columns:
    60px 160px 160px minmax(150px, 250px) minmax(90px, auto)
    32px;
`;

export const Header = styled.div`
  ${gridTemplateColumns}
  width: 100%;
  display: grid;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const Label = styled.span`
  font-family: ${fonts.GilroyBold};
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.gray3};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const ItemContent = styled.a<{ pointer?: boolean }>`
  ${gridTemplateColumns}
  width: 100%;
  display: grid;
  align-items: center;
  column-gap: 16px;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `};
  transition: 200ms ease;

  :hover {
    transform: scale(1.01);
  }
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 11px;
  text-transform: uppercase;
  color: ${colors.gray4};
`;

export const IconDetail = styled(ChevronRightCircle).attrs({ size: 20 })`
  color: ${colors.gray3};
`;

export const ButtonDetail = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
`;
