import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import {
  EditAlert,
  ListAlerts,
  NewAlert,
  EditCompany,
  ListCompanies,
  NewCompany,
  ListCarriers,
  EditCarrier,
  NewCarrier,
  ListClients,
  EditClient,
  NewClient,
  ListClientTypes,
  EditClientType,
  NewClientType,
  ListBusiness,
  NewBusiness,
  EditBusiness,
  ListBusinessUnits,
  NewBusinessUnit,
  EditBusinessUnit,
  ListUsers,
  NewUser,
  EditUser,
  ListProducts,
  NewProduct,
  EditProduct,
  EditProductType,
  ListProductTypes,
  NewProductType,
  EditVehicle,
  ListVehicles,
  NewVehicle,
  EditVehicleType,
  ListVehicleTypes,
  NewVehicleType,
  NewPalletType,
  ListPalletTypes,
  EditPalletType,
  ListAlertEmailLogs,
  ShowAlertEmailLogs,
  EditDocumentCategory,
  ListDocumentCategory,
  NewDocumentCategory,
  ListGroups,
  NewGroup,
  EditGroup,
  ListPermissions,
  EditPermission,
  ListTransitTimes,
  ListZipcodeInternacional,
  NewZipcodeInternacional,
  EditZipcodeInternacional,
} from "pages/Settings";

import { Main } from "pages/Main";
import { SettingMain } from "pages/Settings";
import { OriginDocuments } from "pages/OriginDocuments";
import NewTransitTime from "pages/Settings/TransitTimesAr/NewTransitTime";
import EditTransitTime from "pages/Settings/TransitTimesAr/EditTransitTimes";

interface Props {}

export const SettingsRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute path="/base" exact component={Main} />
      <PrivateRoute
        exact
        path="/settings"
        component={SettingMain}
        hasPermission="accessConfigurator"
      />
      <PrivateRoute
        exact
        path="/settings/alert-email-logs"
        component={ListAlertEmailLogs}
        hasPermission="historyAlert"
      />
      <PrivateRoute
        exact
        path="/settings/alert-email-logs/:id"
        component={ShowAlertEmailLogs}
        hasPermission="historyAlert"
      />
      <PrivateRoute
        exact
        path="/settings/alerts"
        component={ListAlerts}
        hasPermission="listAlert"
      />
      <PrivateRoute
        exact
        path="/settings/alert/new"
        component={NewAlert}
        hasPermission="insertAlert"
      />
      <PrivateRoute
        exact
        path="/settings/alert/:id"
        component={EditAlert}
        hasPermission="editAlert"
      />
      <PrivateRoute
        exact
        path="/settings/companies"
        component={ListCompanies}
        hasPermission="listCompany"
      />
      <PrivateRoute
        exact
        path="/settings/company/new"
        component={NewCompany}
        hasPermission="insertCompany"
      />
      <PrivateRoute
        exact
        path="/settings/company/:id"
        component={EditCompany}
        hasPermission="editCompany"
      />
      <PrivateRoute
        exact
        path="/settings/carriers"
        component={ListCarriers}
        hasPermission="listCarriers"
      />
      <PrivateRoute
        exact
        path="/settings/carrier/new"
        component={NewCarrier}
        hasPermission="insertCarriers"
      />
      <PrivateRoute
        exact
        path="/settings/carrier/:id"
        component={EditCarrier}
        hasPermission="editCarriers"
      />
      <PrivateRoute
        exact
        path="/settings/clients"
        component={ListClients}
        hasPermission="listClient"
      />
      <PrivateRoute
        exact
        path="/settings/client/new"
        component={NewClient}
        hasPermission="insertClient"
      />
      <PrivateRoute
        exact
        path="/settings/client/:id"
        component={EditClient}
        hasPermission="editClient"
      />
      <PrivateRoute
        exact
        path="/settings/client-types"
        component={ListClientTypes}
        hasPermission="listClientType"
      />
      <PrivateRoute
        exact
        path="/settings/client-type/new"
        component={NewClientType}
        hasPermission="insertClientType"
      />
      <PrivateRoute
        exact
        path="/settings/client-type/:id"
        component={EditClientType}
        hasPermission="editClientType"
      />
      <PrivateRoute
        exact
        path="/settings/business"
        component={ListBusiness}
        hasPermission="listBusinessLine"
      />
      <PrivateRoute
        exact
        path="/settings/business/new"
        component={NewBusiness}
        hasPermission="insertBusinessLine"
      />
      <PrivateRoute
        exact
        path="/settings/business/:id"
        component={EditBusiness}
        hasPermission="editBusinessLine"
      />
      <PrivateRoute
        exact
        path="/settings/business-units"
        component={ListBusinessUnits}
        hasPermission="listBusinessUnit"
      />
      <PrivateRoute
        exact
        path="/settings/business-units/new"
        component={NewBusinessUnit}
        hasPermission="insertBusinessUnit"
      />
      <PrivateRoute
        exact
        path="/settings/business-units/:id"
        component={EditBusinessUnit}
        hasPermission="editBusinessUnit"
      />
      <PrivateRoute
        exact
        path="/settings/users"
        component={ListUsers}
        hasPermission="listUser"
      />
      <PrivateRoute
        exact
        path="/settings/user/new"
        component={NewUser}
        hasPermission="insertUser"
      />
      <PrivateRoute
        exact
        path="/settings/user/:id"
        component={EditUser}
        hasPermission="editUser"
      />
      <PrivateRoute
        exact
        path="/settings/products"
        component={ListProducts}
        hasPermission="listProduct"
      />
      <PrivateRoute
        exact
        path="/settings/products/new"
        component={NewProduct}
        hasPermission="insertProduct"
      />
      <PrivateRoute
        exact
        path="/settings/products/:id"
        component={EditProduct}
        hasPermission="editProduct"
      />

      <PrivateRoute
        exact
        path="/settings/product-types"
        component={ListProductTypes}
        hasPermission="listProductType"
      />

      <PrivateRoute
        exact
        path="/settings/product-types/new"
        component={NewProductType}
        hasPermission="insertProductType"
      />

      <PrivateRoute
        exact
        path="/settings/product-types/:id"
        component={EditProductType}
        hasPermission="editProductType"
      />

      <PrivateRoute
        exact
        path="/settings/vehicles"
        component={ListVehicles}
        hasPermission="listVehicle"
      />
      <PrivateRoute
        exact
        path="/settings/vehicle/new"
        component={NewVehicle}
        hasPermission="insertVehicle"
      />
      <PrivateRoute
        exact
        path="/settings/vehicle/:id"
        component={EditVehicle}
        hasPermission="editVehicle"
      />
      <PrivateRoute
        exact
        path="/settings/vehicle-types"
        component={ListVehicleTypes}
        hasPermission="listVehicleType"
      />
      <PrivateRoute
        exact
        path="/settings/vehicle-type/new"
        component={NewVehicleType}
        hasPermission="insertVehicleType"
      />
      <PrivateRoute
        exact
        path="/settings/vehicle-type/:id"
        component={EditVehicleType}
        hasPermission="editVehicleType"
      />
      <PrivateRoute
        exact
        path="/settings/pallet-types"
        component={ListPalletTypes}
        hasPermission="listPallets"
      />

      <PrivateRoute
        exact
        path="/settings/pallet-types/new"
        component={NewPalletType}
        hasPermission="insertPallets"
      />

      <PrivateRoute
        exact
        path="/settings/pallet-types/:id"
        component={EditPalletType}
        hasPermission="editPallets"
      />

      <PrivateRoute
        exact
        path="/settings/document-category"
        component={ListDocumentCategory}
        hasPermission="listDocCategory"
      />

      <PrivateRoute
        exact
        path="/settings/document-category/new"
        component={NewDocumentCategory}
        hasPermission="insertDocCategory"
      />

      <PrivateRoute
        exact
        path="/settings/document-category/:id"
        component={EditDocumentCategory}
        hasPermission="editDocCategory"
      />

      <PrivateRoute
        exact
        path="/settings/groups"
        component={ListGroups}
        hasPermission="listProfileUser"
      />
      <PrivateRoute
        exact
        path="/settings/groups/new"
        component={NewGroup}
        hasPermission="insertProfileUser"
      />
      <PrivateRoute
        exact
        path="/settings/groups/:id"
        component={EditGroup}
        hasPermission="editProfileUser"
      />
      <PrivateRoute
        exact
        path="/settings/permissions"
        component={ListPermissions}
        hasPermission="listPermission"
      />
      <PrivateRoute
        exact
        path="/settings/permissions/:id"
        component={EditPermission}
        hasPermission="editPermision"
      />
      <PrivateRoute
        path="/settings/origin-documents"
        exact
        component={OriginDocuments}
        hasPermission="relatoriosGerenciais"
      />
      <PrivateRoute
        path="/settings/transit-time-ar"
        exact
        component={ListTransitTimes}
        hasPermission="listClient"
      />
      <PrivateRoute
        path="/settings/transit-time-ar/new"
        exact
        component={NewTransitTime}
        hasPermission="listClient"
      />
      <PrivateRoute
        path="/settings/transit-time-ar/:id"
        exact
        component={EditTransitTime}
        hasPermission="listClient"
      />

      <PrivateRoute
        path="/settings/zipcode-internacional"
        exact
        component={ListZipcodeInternacional}
        hasPermission="listZipcodeInternacional"
      />
      <PrivateRoute
        path="/settings/zipcode-internacional/new"
        exact
        component={NewZipcodeInternacional}
        hasPermission="insertZipcodeInternacional"
      />
      <PrivateRoute
        path="/settings/zipcode-internacional/:id"
        exact
        component={EditZipcodeInternacional}
        hasPermission="editZipcodeInternacional"
      />
    </Switch>
  );
};
