export const translations ={
  obrigatorio: {
    pt: 'Obrigatório',
    en: 'Mandatory',
    de: 'Pflichtfeld',
    es: 'Obligatorio',
  },
  titulo: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
    },
   transitTime: {
    pt: 'Novo Transit Time',
    en: 'New Transit Time',
    de: 'Neue Transitzeit',
    es: 'Nuevo tiempo de tránsito',
  },
  transportadora: {
    pt: 'Transportadora',
    en: 'Carrier',
    de: 'Spediteur',
    es: 'Transportadora',
  },
  tipo: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Typ',
    es: 'Escribe',
  },
  origem: {
    pt: 'Origem',
    en: 'Origin',
    de: 'Herkunft',
    es: 'Origen',
  },
  destino: {
    pt: 'Destino',
    en: 'Destiny',
    de: 'Ziel',
    es: 'Destino',
  },
    kmInicial: {
    pt: 'KM Inicial',
    en: 'Initial KM',
    de: 'Km Beginn',
    es: 'KM inicial',
  },
  kmFinal: {
    pt: 'KM Final',
    en: 'Final KM',
    de: 'Km Ende',
    es: 'Schluss-KM',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Zurück',
    es: 'Regresa',
  },
  uf: {
    pt: 'UF',
    en: 'State',
    de: 'Bundesstaat',
    es: 'Estado',
  },
  peso: {
    pt: 'Peso (Kg)',
    en: 'Weight (Kg)',
    de: 'Gewicht (Kg)',
    es: 'Peso (Kg)',
  },
  inicio: {
    pt: 'Inicio (Dias)',
    en: 'Start (Days)',
    de: 'Beginn (Tage)',
    es: 'Inicio (Días)',
  },
  horaCorte: {
    pt: 'Hora de Corte',
    en: 'Cutting time',
    de: 'Schlusszeit',
    es: 'Tiempo de corte',
  },
  validade: {
    pt: 'Validade',
    en: 'Shelf life',
    de: 'Gültigkeit',
    es: 'Duracion',
  },
  contagemFracionada: {
    pt: 'Contagem Fracionado',
    en: 'Fractional Count',
    de: 'Zählung Teilladung',
    es: 'Cuenta fraccionaria',
  },
  prazoFracionada: {
    pt: 'Prazo para Fracionado',
    en: 'Term for Fractionated',
    de: 'Laufzeit für Teilladung',
    es: 'Término para Fraccionado',
  },
  contagemLotacao: {
    pt: 'Contagem Lotação',
    en: 'Capacity Count',
    de: 'Zählung Vollladung',
    es: 'Recuento de capacidad',
  },
  prazoLotacao: {
    pt: 'Prazo para Lotação',
    en: 'Term for Capacity',
    de: 'Laufzeit für die Vollladung',
    es: 'Plazo de Capacidad',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Entfernen',
    es: 'Eliminar',
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Registrieren',
    es: 'Registrar'
  },
  countryDes:{
    pt: "País destino",
    en: "Country of destiny",
    de: "Zielland ",
    es: "Pais de destino"
  },
  countryOri:{
    pt: "País origem",
    en: "Country of origin",
    de: "Herkunftsland",
    es: "País de origen"
  },
  zipcodeOri: {
    pt: 'Código postal da origem',
    en: 'Zipcode of origin',
    de: 'Postleitzahl des Ursprungs',
    es: 'Código postal de origen'
  },
  zipcodeDes: {
    pt: 'Código postal do destino',
    en: 'Zipcode of destiny',
    de: 'Postleitzahl des Reiseziels',
    es: 'Código postal de destino'
  }
};