import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { IGroup } from "interfaces/group";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";
interface IGridGroupsProps {
  groups: IGroup[] | Record<string, any>[];
}

interface IgroupProps {
  group: IGroup | Record<string, any>;
}

const Item: React.FC<IgroupProps> = ({ group }) => {
  const history = useHistory();
  const { checkUserPermission } = usePermissions();
  const havePermission = checkUserPermission("editProfileUser");
  return (
    <S.ItemContainer
      onClick={() =>
        havePermission && history.push(`/settings/groups/${group.id}`)
      }
    >
      <S.ItemContent pointer={havePermission}>
        <S.ItemValue>{group.id || "--"}</S.ItemValue>
        <S.ItemValue>{group.name || "--"}</S.ItemValue>

        {havePermission && (
          <S.ButtonDetail>
            <S.IconDetail />
          </S.ButtonDetail>
        )}
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridGroups: React.FC<IGridGroupsProps> = ({ groups = [] }) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.Label>Id</S.Label>
        <S.Label>{getTranslation("nome")}</S.Label>
      </S.Header>
      {groups.length > 0 &&
        groups.map((group) => <Item group={group} key={group.id} />)}
    </S.Container>
  );
};
