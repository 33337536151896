export const translations = {
  novoCategoria: {
    pt: 'Nova categoria de documentos',
    en: 'New document category',
    de: 'Neue Dokumentenkategorie',
    es: 'NNueva categoría de documento',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },

};