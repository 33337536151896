export const translations = {
  titulo: {
    pt: "Logar como outro usuário?",
    en: "Log in as another user?",
    de: "Als anderer Benutzer einloggen?",
    es: "¿Iniciar sesión como otro usuario?",
  },
  msg: {
    pt: "Você está prestes a logar como outro usuário. Deseja continuar?",
    en: "You are about to log in as another user. Do you want to continue?",
    de: "Sie stehen kurz davor, sich als anderer Benutzer anzumelden. Möchten Sie fortfahren?",
    es: "Estás a punto de iniciar sesión como otro usuario. ¿Deseas continuar?",
  },
  continuar: {
    pt: "Continuar",
    en: "Continue",
    de: "Weiter",
    es: "Continuar",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "Abbrechen",
    es: "Cancelar",
  },
};
