import { call, put } from "redux-saga/effects";

import { apiGeneral, notify } from "services";
import { IFechRequest } from "interfaces/fetch-duck";
import { requestErrorHandler } from "utils";
import { FetchZipcodeInternacionalActions } from "store/ducks/settings/zipcode-internacional";

export function* fetchZipcodeInternacionalRequest(action: any) {
  try {
    const { id, onSuccess } = action as IFechRequest;
    const { data } = yield call(apiGeneral.get, `/zipcode-internacional/${id}`);

    yield put(FetchZipcodeInternacionalActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(FetchZipcodeInternacionalActions.failure(errorMessage));
  }
}
