import styled from "styled-components";
import { Loading, File, CloudUpload } from "styles/styled-components";

export {
  PageContainer,
  PageHeader,
  PageContent,
  ButtonMini,
} from "styles/styled-components";

export const IconFile = styled(File).attrs({ size: 24 })``;
export const IconUpload = styled(CloudUpload).attrs({ size: 24 })`
  margin-right: 10px;
`;

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const HeaderButtons = styled.div`
  display: flex;
  button {
    &:first-child {
      margin-right: 24px;
    }
  }
`;
