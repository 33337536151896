import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  FetchDocumentCategoryState,
  FetchDocumentCategoryActions,
  UpdateDocumentCategoryActions,
  UpdateDocumentCategoryState,
  DeleteDocumentCategoryActions,
  DeleteDocumentCategoryState,
} from "store/ducks/settings/document-category";

import { useValidation, usePermissions } from "hooks";

import * as S from "./styles";
import { MainContainer, Modal, Alert } from "components/shared";
import { Input } from "components/shared/Form";
import { useTranslation } from "hooks";
import { translations } from "./translations";
interface IParams {
  id: string;
}

export const EditDocumentCategory: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const { checkUserPermission } = usePermissions();

  const { data: dataFetchDocumentCategory, loading } =
    useSelector<RootStateOrAny>(
      (state) => state.fetchDocumentCategory
    ) as FetchDocumentCategoryState;

  const { loading: loadingDeleteDocumentCategory } =
    useSelector<RootStateOrAny>(
      (state) => state.deleteDocumentCategory
    ) as DeleteDocumentCategoryState;

  const { loading: loadingUpdateDocumentCategory } =
    useSelector<RootStateOrAny>(
      (state) => state.updateDocumentCategory
    ) as UpdateDocumentCategoryState;

  const onSuccess = useCallback(() => {
    history.push("/settings/document-category");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required("Obrigatório"),
          description: Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(UpdateDocumentCategoryActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, id, onSuccess]
  );

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      formRef.current.setData(data);
    }
  }, []);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchDocumentCategoryActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const onDeleteSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  const deleteData = useCallback(() => {
    dispatch(DeleteDocumentCategoryActions.request(id, onDeleteSuccess));
  }, [dispatch, id, onDeleteSuccess]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MainContainer>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`${getTranslation("removerTitulo")} ${
            dataFetchDocumentCategory?.name
          }`}
          text={`${getTranslation("removerDescricao")}`}
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction={getTranslation("remover")}
          isLoading={loadingDeleteDocumentCategory}
        />
      </Modal>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}{" "}
          <span>{getTranslation("editarCategoria")}</span>
          {loading && <S.LoadingPage />}
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input name="name" label={getTranslation("nome")} />
              <Input name="description" label={getTranslation("descricao")} />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>

              {checkUserPermission("deleteDocCategory") && (
                <S.Button
                  btStyle="danger"
                  type="button"
                  onClick={() => setModalOpen(true)}
                >
                  {getTranslation("remover")}
                </S.Button>
              )}

              <S.Button type="submit">
                {loadingUpdateDocumentCategory ? (
                  <S.Loading />
                ) : (
                  getTranslation("cadastrar")
                )}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
