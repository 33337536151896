interface RiskNumbers {
  classes: number;
  values: string[];
}

export const riskNumbers: RiskNumbers[] = [
  { 
    classes: 2,
    values: ["20", "22", "223", "225", "23", "239", "25", "26", "263", "265", "268"]
  },
  { 
    classes: 3,
    values: ["30", "323", "X323", "33", "333", "X333", "336", "338", "X338", "339", "36", "362", "X362", "368", "38", "382", "39"]
  },
  { 
    classes: 4,
    values: ["40", "X423", "43", "44", "446", "46", "462", "X462", "48", "482", "X482"],
  },
  {
    classes: 5,
    values: ["50", "556", "558", "559", "56", "568", "58", "59"],
  },
  { 
    classes: 6,
    values: ["60", "606", "623", "63", "638", "639", "64", "642", "65", "66", "663", "664", "665", "668", "669", "68", "69"],
  },
  { 
    classes: 7,
    values: ["70", "72", "723", "73", "74", "75", "76", "78"]},
  { 
    classes: 8,
    values: ["80", "X80", "823", "83", "X83", "839", "X839", "84", "842", "85", "856", "86", "88", "X88", "883", "884", "885", "886", "X886", "89"],
  },
  { 
    classes: 9,
    values: ["90", "99"],
  },
]