export const translations = {
  id: {
    pt: 'ID',
    en: 'ID',
    es: 'ID',
    de: 'ID',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Pflichtfeld',
    es: 'Obligatorio',
  },
  codigoSAP: {
    pt: 'Cód. SAP',
    en: 'SAP code',
    de: 'SAP-Code',
    es: 'Cód. SAP',
  },
  cnpjRaiz: {
    pt: 'Raíz CNPJ/CPF',
    en: 'CNPJ/CPF root',
    de: 'ID CNPJ/CPF',
    es: 'Raíz CNPJ/CPF',
  },
  customizacao: {
    pt: 'Customer Seg.',
    en: 'Customer Mon.',
    de: 'Kundensegment.',
    es: 'Cliente lun.',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Firmenname',
    es: 'Razón Social',
  },
  excessao: {
    pt: 'Excessões de Filial',
    en: 'Branch Excesses',
    de: 'Ausgeschlossene Filiale',
    es: 'Excesos de sucursales',
  },
  businessLine: {
    pt: 'Business Line',
    en: 'Business Line',
    de: 'Geschäftslinie',
    es: 'Línea de negocios',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'To add',
    de: 'Hinzufügen',
    es: 'Para agregar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
};
