import { call, put } from 'redux-saga/effects';

import { apiGeneral, notify } from 'services';
import { IFechRequest } from 'interfaces/fetch-duck';
import { requestErrorHandler } from 'utils';
import { FetchClientCodeActions } from 'store/ducks/settings/client-codes';

export function* fetchClientCodeRequest(action: any) {
  try {
    const { id, onSuccess } = action as IFechRequest;
    const { data } = yield call(apiGeneral.get, `/client-codes/${id}`);

    yield put(FetchClientCodeActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify('error', errorMessage);
    yield put(FetchClientCodeActions.failure(errorMessage));
  }
}
