import axios from 'axios';

const hostname = window.location.hostname;

const environment = {
  local: {
    apiGeneral: process.env.REACT_APP_URL_BASE_LOCAL as string,
  },
  production: {
    apiGeneral: process.env.REACT_APP_URL_BASE as string,
  },
  homologacao: {
    apiGeneral: process.env.REACT_APP_URL_BASE_HML as string,
  },
};

const getEnvironment = () => {
  if (hostname === 'localhost') {
    return environment.local;
  }

  if (hostname === 'evonik.webcol.systems') {
    return environment.production;
  }

  if (hostname === 'hml-evonik.webcol.systems') {
    return environment.homologacao;
  }

  return environment.homologacao;
};

if (process.env.NODE_ENV === 'development') {
  console.log('Ambiente de desenvolvimento');
}

const baseURL = getEnvironment();

export const apiGeneral = axios.create({
  baseURL: baseURL.apiGeneral,
});

export const queryBuilder = (payload: Record<string, any>): string => {
  const validObject: Record<string, any> = {};

  for (const [param, value] of Object.entries(payload)) {
    if (value) Object.assign(validObject, { [param]: value });
  }

  const searchParams = new URLSearchParams(validObject).toString();
  return searchParams;
};

export const formDataBuilder = (payload: Record<string, any>): FormData => {
  const formData = new FormData();

  for (const [param, value] of Object.entries(payload)) {
    if (value instanceof File) {
      formData.append(param, value, value.name);
    } else if (value) {
      formData.append(param, value);
    }
  }

  return formData;
};
