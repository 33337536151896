import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation, useValidation } from "hooks";
import { MainContainer, Modal, Alert } from "components/shared";
import { Input, Select } from "components/shared/Form";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { translations } from "./translations";
import * as S from "./styles";

import {
  UpdateDocumentActions,
  UpdateDocumentState,
  ShowDocumentActions,
  ShowDocumentState,
  DeleteDocumentActions,
  DeleteDocumentState,
  PaginateDocumentActions,
} from "store/ducks/documents";

import {
  ListDocumentCategoryActions,
  ListDocumentCategoryState,
} from "store/ducks/settings/document-category";

interface IParams {
  id: string;
}

export const EditDocument: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const { getTranslation } = useTranslation(translations);
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.createDocument
  ) as UpdateDocumentState;

  const { data, loading: loadingShow } = useSelector<RootStateOrAny>(
    (state) => state.showDocument
  ) as ShowDocumentState;

  const { loading: loadingDeleteDocument } = useSelector<RootStateOrAny>(
    (state) => state.deleteDocument
  ) as DeleteDocumentState;

  const { data: categoryOption, loading: loadingCategory } =
    useSelector<RootStateOrAny>(
      (state) => state.listDocumentCategory
    ) as ListDocumentCategoryState;

  const onSuccess = useCallback(() => {
    dispatch(PaginateDocumentActions.reset());
    history.push("/docs");
  }, [history, dispatch]);

  const onFetchSuccess = useCallback(() => {
    if (formRef.current && data && categoryOption.length) {
      const category_id = categoryOption.find(
        (el: any) => el?.value === data.category_id
      );

      formRef.current.setData(data);
      formRef.current.setFieldValue("category_id", category_id);
    }
  }, [categoryOption, data]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          category_id: Yup.string().required("Obrigatório"),
          description: Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        delete data.file_name;

        dispatch(UpdateDocumentActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, id]
  );

  const deleteData = useCallback(() => {
    dispatch(DeleteDocumentActions.request(id, onSuccess));
  }, [dispatch, id, onSuccess]);

  const getData = useCallback(() => {
    dispatch(ShowDocumentActions.request(id));
    dispatch(ListDocumentCategoryActions.request({ all: true }));
  }, [dispatch, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data && categoryOption.length) {
      onFetchSuccess();
    }
  }, [data, onFetchSuccess, categoryOption]);

  return (
    <MainContainer>
      <Modal isOpen={modalOpen}>
        <Alert
          title={`${getTranslation("removerTitulo")} ${data?.file_name}`}
          text={`${getTranslation("removerDescricao")}`}
          close={() => setModalOpen(false)}
          action={deleteData}
          labelAction="Remover"
          isLoading={loadingDeleteDocument}
        />
      </Modal>

      <S.PageHeader>
        <h1>
          <S.IconFile />
          {getTranslation("docs")} - {getTranslation("editar")}
          {loadingShow && <S.LoadingPage />}
        </h1>

        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>

      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input name="file_name" label={getTranslation("file")} disabled />
              <Select
                name="category_id"
                label={getTranslation("categoria")}
                options={categoryOption}
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="description" label={getTranslation("descricao")} />
            </S.FormRow>
          </S.BoxContainer>

          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={() => setModalOpen(true)}
                disabled={loadingShow || loading || loadingDeleteDocument}
              >
                Remover
              </S.Button>

              <S.Button
                type="submit"
                disabled={
                  loadingShow ||
                  loadingCategory ||
                  loading ||
                  loadingDeleteDocument
                }
              >
                {loading ? <S.Loading /> : getTranslation("salvar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
