export const translations = {
  nome: {
    pt: "Nome do documento",
    en: "Document name",
    de: "Dokumentname",
    es: "Nombre del documento",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
  categoria: {
    pt: "Categoria",
    en: "Category",
    de: "Kategorie",
    es: "Categoría",
  },
  download: {
    pt: "Download",
    en: "Download",
    de: "Herunterladen",
    es: "Descargar",
  },
};
