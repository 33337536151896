export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoTipo: {
    pt: 'Novo Tipo',
    en: 'New Type',
    de: 'Nuevo tipo',
    es: 'Nuevo tipo',
  },
  novoTipoveiculo: {
    pt: 'Novo Tipo de Veículo',
    en: 'New Vehicle Type',
    de: 'Neuer Fahrzeugtyp',
    es: 'Nuevo tipo de vehículo',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
};