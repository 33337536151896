import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation, useValidation } from "hooks";
import { MainContainer } from "components/shared";
import { Input, Select, FileInput } from "components/shared/Form";

import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { translations } from "./translations";
import * as S from "./styles";

import {
  CreateDocumentActions,
  CreateDocumentState,
  PaginateDocumentActions,
} from "store/ducks/documents";

import {
  ListDocumentCategoryActions,
  ListDocumentCategoryState,
} from "store/ducks/settings/document-category";

export const NewDocument: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.createDocument
  ) as CreateDocumentState;

  const { data: categoryOption } = useSelector<RootStateOrAny>(
    (state) => state.listDocumentCategory
  ) as ListDocumentCategoryState;

  const onSuccess = useCallback(() => {
    dispatch(PaginateDocumentActions.reset());
    history.push("/docs");
  }, [history, dispatch]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const acceptExt = [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "application/pdf",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

        const schema = Yup.object().shape({
          doc: Yup.mixed()
            .required("Obrigatório")
            .test(
              "file",
              "O arquivo deve ser PNG, JPG, JPEG, PDF ou Excel",
              async (value) => {
                if (!value) return false;
                if (!acceptExt.includes(value.type)) return false;

                return true;
              }
            )
            .test(
              "fileSize",
              `O tamanho máximo do arquivo é de 20 MB`,
              (value) => {
                if (!value) return true;

                return value.size <= 20971520;
              }
            ),
          category_id: Yup.string().required("Obrigatório"),
          description: Yup.string(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();
        formData.append("doc", data.doc);
        formData.append("category_id", data.category_id);
        formData.append("description", data.description);

        dispatch(CreateDocumentActions.request(formData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  const getData = useCallback(() => {
    dispatch(ListDocumentCategoryActions.request({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconFile />
          {getTranslation("docs")} - {getTranslation("upload")}
          {loading && <S.LoadingPage />}
        </h1>

        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>

      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <FileInput name="doc" label={getTranslation("file")} />
              <Select
                name="category_id"
                label={getTranslation("categoria")}
                options={categoryOption}
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="description" label={getTranslation("descricao")} />
            </S.FormRow>
          </S.BoxContainer>

          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
