export const translations = {
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Center',
    es: 'Centrar',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  businessLine: {
    pt: 'Business Line',
    en: 'Business Line',
    de: 'Geschäftslinie',
    es: 'Línea de negocios',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'Add',
    de: 'Addieren',
    es: 'Agregar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
};