export const translations = {
  novoVeiculo: {
    pt: 'Novo Veículo',
    en: 'New Vehicle',
    de: 'Neues Fahrzeug',
    es: 'Vehículo Nuevo',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
};