import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 220px;
  margin: 0 16px;

  form {
    width: 100%;
  }
`;
