export const translations = {
  docs: {
    pt: "Documentos",
    en: "Documents",
    de: "Dokumente",
    es: "Documentos",
  },
  enviarDoc: {
    pt: "Enviar documento	",
    en: "Send document",
    de: "Dokument senden",
    es: "Enviar documento",
  },
  uploadDoc: {
    pt: "Upload de documento",
    en: "Document upload",
    de: "Dokument hochladen",
    es: "Subida de documento",
  },
  upload: {
    pt: "Upload",
    en: "Upload",
    de: "hochladen",
    es: "Subir",
  },
  downloadDoc: {
    pt: "Download",
    en: "Download",
    de: "Herunterladen",
    es: "Descargar",
  },
};
