import { call, put } from 'redux-saga/effects';

import { apiGeneral, notify } from 'services';
import { ICreateRequest } from 'interfaces/create-duck';
import { requestErrorHandler } from 'utils';
import { CreateClientCodeActions } from 'store/ducks/settings/client-codes';

export function* createClientCodeRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(
      apiGeneral.post,
      `/client-codes`,
      postData
    );  

    const { data } = responseBody;
    yield put(CreateClientCodeActions.success(data));
    notify('success', 'Código SAP cadastrado com sucesso');
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify('error', errorMessage);
    yield put(CreateClientCodeActions.failure(errorMessage));
  }
}
