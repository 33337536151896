import { ICitiesOptions, ICity } from 'interfaces/city';
import { useEffect, useState } from 'react';
import { apiGeneral } from 'services';

export const useCities = (initialState = '') => {
  const [state, setState] = useState(initialState);
  const [cities, setCities] = useState<ICitiesOptions[]>([]);

  const fetchCities = async (query: string) => {
    const { data } = await apiGeneral.get(`/cities?state=${query}`);

    const comboOptions = data.map((city: ICity) => ({
      value: city.name,
      label: city.name,
      ibge: city.ibge,
    }));

    setCities(comboOptions);
  }

  useEffect(() => {
    fetchCities(state);
  }, [state]);

  return { state, cities, setState };
  
};