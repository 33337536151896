import styled from "styled-components";
import { colors } from "styles";

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: ${colors.deepPurple};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
`;