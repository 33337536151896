import { call, put } from "redux-saga/effects";

import { apiGeneral, notify } from "services";
import { ICreateRequest } from "interfaces/create-duck";
import { requestErrorHandler } from "utils";
import { CreateCarrierContactActions } from "store/ducks/settings/carrier-contact";

export function* createCarrierContactRequest(action: any) {
  try {
    const { postData, onSuccess } = action as ICreateRequest;

    const { data: responseBody } = yield call(
      apiGeneral.post,
      `/carrier-contact`,
      postData
    );

    const { data } = responseBody;
    yield put(CreateCarrierContactActions.success(data));
    notify("success", "Contato salvo com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateCarrierContactActions.failure(errorMessage));
  }
}
