export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoAlerta: {
    pt: 'Novo Alerta',
    en: 'New Alert',
    de: 'Neue Benachrichtigung',
    es: 'Nueva alerta',
  },
};