export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  editarProduto: {
    pt: 'Editar Produto',
    en: 'Edit Product',
    de: 'Produkt bearbeiten',
    es: 'Editar producto',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  tipoProduto: {
    pt: 'Tipo de produto',
    en: 'Product type',
    de: 'Produktart',
    es: 'Tipo de producto',
  },
  classe: {
    pt: 'Classe de Risco',
    en: 'Risk class',
    de: 'Risikoklasse',
    es: 'Clase de riesgo',
  },
  numero: {
    pt: 'Número de Risco',
    en: 'Risk Number',
    de: 'Risikonummer',
    es: 'Número de riesgo',
  },
  riscoSub: {
    pt: 'Risco Subsidiário',
    en: 'Subsidiary Risk',
    de: 'Nebenrisiko',
    es: 'Riesgo secundário',
  },
  grupo: {
    pt: 'Grupo de embalagem',
    en: 'Packing group',
    de: 'Verpackungsgruppe',
    es: 'Grupo de embalaje',
  },
  numeroOnuSAP: {
    pt: 'ONU SAP',
    en: 'SAP ONU',
    de: 'SAP-ONU',
    es: 'SAP ONU',
  },
  tipoPalete: {
    pt: 'Tipo de palete',
    en: 'Pallet type',
    de: 'Palettentyp',
    es: 'Tipo de palet',
  },
  quantidadePalete: {
    pt: 'Quantidade por Palete',
    en: 'Quantity per Pallet',
    de: 'Menge pro Palette',
    es: 'Cantidad por palet',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Löschen',
    es: 'Retirar',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Center',
    es: 'Centrar',
  },
  businessLine: {
    pt: 'Business Line',
    en: 'Business Line',
    de: 'Geschäftslinie',
    es: 'Línea de negocios',
  },
};