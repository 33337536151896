export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoTipo: {
    pt: 'Novo Tipo',
    en: 'New Type',
    de: 'Nuevo tipo',
    es: 'Nuevo tipo',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  editarTipoVeiculo: {
    pt: 'Editar Tipo de Veículo',
    en: 'Edit Vehicle Type',
    de: 'Fahrzeugtyp bearbeiten',
    es: 'Editar tipo de vehículo',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
};