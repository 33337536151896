import React, { useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "hooks";
import { Modal } from "components/shared";
import { translations } from "./translations";
import * as S from "./styles";
import { ImpersonateActions, ImpersonateState } from "store/ducks/auth";

interface IFilterProps {
  email: string;
  openModal: boolean;
  close: () => void;
}

export const ImpersonateModal: React.FC<IFilterProps> = ({
  email,
  openModal = false,
  close,
}) => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  const history = useHistory();

  console.log({email});
  
  const { loading } = useSelector<RootStateOrAny, ImpersonateState>(
    (state) => state.impersonate
  );

  const onSuccess = useCallback(() => {
    dispatch(ImpersonateActions.reset());
    history.push("/");
  }, [history, dispatch]);

  const handleRequest = useCallback(() => {
    dispatch(ImpersonateActions.request({ email }, onSuccess));
  }, [dispatch, onSuccess, email]);

  return (
    <S.Container>
      <Modal isOpen={openModal}>
        <S.ModalContainer>
          <S.Header>
            <S.Title>{getTranslation("titulo")}</S.Title>
          </S.Header>
          <S.Text>{getTranslation("msg")}</S.Text>
          <S.ButtonsWrapper>
            <S.Button btStyle="cancel" type="button" onClick={close}>
              {getTranslation("cancelar")}
            </S.Button>
            <S.Button type="button" onClick={handleRequest}>
              {loading ? <S.Loading /> : getTranslation("continuar")}
            </S.Button>
          </S.ButtonsWrapper>
        </S.ModalContainer>
      </Modal>
    </S.Container>
  );
};
