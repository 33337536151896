export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  novoPerfilUsuario: {
    pt: "Novo Perfil de Usuário",
    en: "New User Profile",
    de: "Neues Benutzerprofil",
    es: "Nuevo perfil de usuario",
  },
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Name",
    es: "Nombre",
  },
  modulo: {
    pt: "Módulo",
    en: "Module",
    de: "Modul",
    es: "Módulo",
  },
  permissoesPerfil: {
    pt: "Permissões de perfil",
    en: "Profile permissions",
    de: "Profilberechtigungen",
    es: "Permisos de perfil",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Löschen",
    es: "Retirar",
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Speichern',
    es: 'Registrar',
  },
};
