import React from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import QRCode from 'react-qr-code';
import { AuthState } from 'store/ducks/auth';
import { AsideMenu, Clock } from 'components/Main';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

export const Main: React.FC = () => {
  const history = useHistory();

  const { data } = useSelector<RootStateOrAny, AuthState>(
    (state) => state.auth
  );
  return (
    <S.Container>
      <Clock />
      <AsideMenu />
      <S.BtnFileContainer>
        <S.ButtonMiniVariant
          btStyle="white"
          onClick={() => history.push('/docs')}
        >
          <S.IconFile />
          Manuais e orientações
        </S.ButtonMiniVariant>
      </S.BtnFileContainer>
      <S.Content>
        <S.TMS />
        {data?.app_access ? (
          <S.ItemWrapper>
            <S.Item>
              <S.Box>
                <S.AvatarSara />
                <S.Name>Sara</S.Name>
                <QRCode
                  value="https://api.whatsapp.com/send/?phone=5524999585260"
                  size={160}
                />
              </S.Box>
              <S.Text>
                Escaneie esse código para iniciar uma conversa com Sara no
                WhatsApp.
              </S.Text>
            </S.Item>
            <S.Item>
              <S.Box>
                <S.AvatarApp />
                <S.Name>Aplicativo Notas</S.Name>
                <QRCode value="https://evonik.webcol.systems/app" size={160} />
              </S.Box>
              <S.Text>
                Escaneie esse código para acessar o aplicativo de consulta de
                notas fiscais Evonik.
              </S.Text>
            </S.Item>
            <S.Item>
              <S.Box>
                <S.AvatarMotorista />
                <S.Name>Aplicativo Motorista</S.Name>
                <QRCode
                  value="https://play.google.com/store/apps/details?id=com.app.motorista"
                  size={160}
                />
              </S.Box>
              <S.Text>
                Escaneie esse código para acessar o aplicativo do motorista.
              </S.Text>
            </S.Item>
          </S.ItemWrapper>
        ) : (
          <> </>
        )}
      </S.Content>
    </S.Container>
  );
};
