import { call, put } from "redux-saga/effects";
import { apiGeneral, notify } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListGroupPermissionsActions } from "store/ducks/settings/group-permissions";

export function* listGroupPermissionRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const { group_id: id } = query;
    const { data } = yield call(apiGeneral.get, `/group-permission/${id}`);
    yield put(ListGroupPermissionsActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListGroupPermissionsActions.failure(errorMessage));
  }
}
