import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import { IState, ISuccessAction, IFailureAction } from 'interfaces/fetch-duck';
import { ClientCode } from 'interfaces/client-codes';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['error'],
    reset: [],
  },
  { prefix: 'FETCH_CLIENT_CODE_' }
);

export interface FetchClientCodeState extends IState {
  data: ClientCode[];
}

export interface FetchClientCodeSuccessAction extends ISuccessAction {
  data: ClientCode[];
}

const INITIAL_STATE: FetchClientCodeState = {
  data: [],
  loading: false,
  error: null,
};

const request = (state: FetchClientCodeState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: FetchClientCodeState,
  action: FetchClientCodeSuccessAction
) =>
  update(state, {
    data: { $set: action.data },
    loading: { $set: false },
  });

const failure = (state: FetchClientCodeState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = (state: FetchClientCodeState) => update(state, {
  loading: { $set: INITIAL_STATE.loading },
  data: { $set: INITIAL_STATE.data },
  error: { $set: INITIAL_STATE.error },
});

export const fetchClientCode = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const FetchClientCodeTypes = Types;
export const FetchClientCodeActions = Creators;
