export const translations = {
  titulo: {
    pt: "E-mails por módulos",
    en: "Emails by modules",
    es: "Correos electrónicos por módulos",
    de: "E-Mails nach Modulen",
  },
  email: {
    pt: "E-mail",
    en: "Email",
    es: "Correo electrónico",
    de: "E-Mail",
  },
  modulos: {
    pt: "Modulos",
    en: "Modules",
    de: "Module",
    es: "Modulos",
  },
  selecioneModulo: {
    pt: "Selecione um módulo",
    en: "Select a module",
    es: "Seleccione un módulo",
    de: "Wählen Sie ein Modul aus",
  },
  utilizarSeparador: {
    pt: "Utilize ; para separar os e-mails",
    en: "Use ; to separate the emails",
    es: "Utilice ; para separar los correos electrónicos",
    de: "Verwenden Sie ; um die E-Mails zu trennen",
  },
  removerEmails: {
    pt: "Você tem certeza que deseja remover os seguintes e-mails:",
    en: "Are you sure you want to remove the following emails:",
    es: "¿Está seguro de que desea eliminar los siguientes correos electrónicos:",
    de: "Sind Sie sicher, dass Sie die folgenden E-Mails entfernen möchten:",
  },
  adicionar: {
    pt: "Adicionar",
    en: "Add",
    es: "Agregar",
    de: "Hinzufügen",
  },
  editar: {
    pt: "Editar",
    en: "Edit",
    es: "Editar",
    de: "Bearbeiten",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    es: "Cancelar",
    de: "Abbrechen",
  },
  salvar: {
    pt: "Salvar",
    en: "Save",
    es: "Guardar",
    de: "Speichern",
  },
  confirmar: {
    pt: "Confirmar",
    en: "Confirm",
    es: "Confirmar",
    de: "Bestätigen",
  },
  obrigatorio: {
    pt: "Obrigtório",
    en: "Mandatory",
    de: "Verpflichtend",
    es: "Obligatorio",
  },
  selecaoInicio: {
    pt: "Você selecionou",
    en: "You have selected",
    de: "Sie haben ",
    es: "Ha seleccionado",
  },
  selecaoFinal: {
    pt: "de contato para deletar",
    en: "contacts to delete",
    de: "Kontakte zur Löschung ausgewählt.",
    es: "contactos para eliminar.",
  },
  tituloDeletar: {
    pt: "Deletar contato",
    en: "Delete contact",
    de: "Kontakt löschen",
    es: "Borrar contacto",
  },
  descricaoDeletarPlural: {
    pt: "Você tem certeza que deseja deletar os seguintes e-mails?",
    en: "Are you sure you want to delete the following emails?",
    de: "Sind Sie sicher, dass Sie die folgenden E-Mails löschen möchten?",
    es: "¿Estás seguro/a de que quieres borrar los siguientes correos electrónicos?",
  },
  descricaoDeletar: {
    pt: "Você tem certeza que deseja deletar o seguinte e-mail?",
    en: "Are you sure you want to delete the following email?",
    de: "Sind Sie sicher, dass Sie die folgende E-Mail löschen möchten?",
    es: "¿Estás seguro/a de que quieres borrar el siguiente correo electrónico?",
  },
};
