import { MainContainer } from "components/shared";
import React, { useCallback, useRef, useEffect } from "react";
import * as S from "./styles";
import { useTranslation, useValidation, usePermissions } from "hooks";
import { translations } from "./translations";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  ListCarriersActions,
  ListCarriersState,
} from "store/ducks/settings/carriers";
import { ListCountriesActions, ListCountriesState } from "store/ducks/country";
import { FormHandles, SubmitHandler } from "@unform/core";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "@unform/web";
import { Input, Select, InputMask } from "components/shared/Form";
import * as Yup from "yup";

import { OptionTypeBase } from "react-select";
import {
  CreateTransitTimeArActions,
  FetchTransitTimeArActions,
  UpdateTransitTimeArActions,
  UpdateTransitTimeArState,
} from "store/ducks/settings/transit-time-ar";

interface CountSelectDataProps extends OptionTypeBase {}
export const countSelectData: CountSelectDataProps[] = [
  { label: "UTEIS", value: "UTEIS" },
  { label: "CORRIDOS", value: "CORRIDOS" },
];

interface IParams {
  id: string;
}
const EditTransitTime = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.updateTransitTimeAr
  ) as UpdateTransitTimeArState;

  const { data: dataCarriers, loading: loadingCarriers } = useSelector<
    RootStateOrAny,
    ListCarriersState
  >((state) => state.listCarriers) as ListCarriersState;

  const { data: dataCountries, loading: loadingCountries } = useSelector<
    RootStateOrAny,
    ListCountriesState
  >((state) => state.listCountries) as ListCountriesState;

  const onSuccess = useCallback(() => {
    history.push("/settings/transit-time-ar");
  }, [history]);

  const fetchCarriers = useCallback(() => {
    dispatch(ListCarriersActions.request({ all: true }));
  }, [dispatch]);

  const onFetchSuccess = useCallback((data) => {
    console.log("DATAFETCH", data);
    if (formRef.current) {
      data.carrier_id = {
        label: data.carrier.trade_name,
        value: data.carrier.id,
      };
      data.count_fractional = {
        label: data.count_fractional,
        value: data.count_fractional,
      };
      data.count_dedicated = {
        label: data.count_dedicated,
        value: data.count_dedicated,
      };

      data.cut_hour = data.cut_hour || "";
      data.valid_until =
        data.valid_until && format(new Date(data.valid_until), "yyyy-MM-dd");
      const origin = dataCountries.find(
        (item) => item.code === data.country_origin
      );
      const destiny = dataCountries.find(
        (item) => item.code === data.country_destiny
      );
      data.country_origin = origin || {};
      data.country_destiny = destiny || {};
      formRef.current.setData(data);
    }
  }, []);

  const fetchCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true }));
  }, [dispatch]);
  const fetchData = useCallback(() => {
    console.log(id);
    if (id) {
      dispatch(FetchTransitTimeArActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          carrier_id: Yup.string().required(getTranslation("obrigatorio")),
          country_origin: Yup.string().required(getTranslation("obrigatorio")),
          country_destiny: Yup.string().required(getTranslation("obrigatorio")),
          zipcode_origin: Yup.string().required(getTranslation("obrigatorio")),
          zipcode_destiny: Yup.string().required(getTranslation("obrigatorio")),
          weight: Yup.string().required(getTranslation("obrigatorio")),
          start: Yup.string().required(getTranslation("obrigatorio")),
          count_fractional: Yup.string().required(
            getTranslation("obrigatorio")
          ),
          deadline_fractional: Yup.string().required(
            getTranslation("obrigatorio")
          ),
          count_dedicated: Yup.string().required(getTranslation("obrigatorio")),
          deadline_dedicated: Yup.string().required(
            getTranslation("obrigatorio")
          ),
          valid_until: Yup.string().required(getTranslation("obrigatorio")),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        data.type = "CIDADE";

        dispatch(UpdateTransitTimeArActions.request(id, data, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, getTranslation, handleFormErrors, onSuccess]
  );
  useEffect(() => {
    fetchCarriers();
    const fetchDataAndCountries = async () => {
      await fetchCountries();
      fetchData();
    };
    fetchDataAndCountries();
  }, [fetchCarriers, fetchCountries, fetchData]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}{" "}
          <span>{getTranslation("transitTime")}</span>
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <Select
              name="carrier_id"
              label={getTranslation("transportadora")}
              isDisabled={loadingCarriers}
              isLoading={loadingCarriers}
              options={dataCarriers}
              placeholder={getTranslation("selecione")}
            />
            <S.FormRow>
              <Select
                name="country_origin"
                label={`${getTranslation("countryOri")}`}
                isDisabled={loadingCountries}
                isLoading={loadingCountries}
                options={dataCountries}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="zipcode_origin"
                label={getTranslation("zipcodeOri")}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="country_destiny"
                label={`${getTranslation("countryDes")}`}
                isDisabled={loadingCountries}
                isLoading={loadingCountries}
                options={dataCountries}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="zipcode_destiny"
                label={getTranslation("zipcodeDes")}
              />
            </S.FormRow>
            <S.FormRow>
              <Input name="weight" label={getTranslation("peso")} />
              <Input name="start" label={getTranslation("inicio")} />
              <InputMask
                name="cut_hour"
                label={getTranslation("horaCorte")}
                mask="99:99"
              />
              <Input
                name="valid_until"
                type="date"
                label={getTranslation("validade")}
              />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="count_fractional"
                label={getTranslation("contagemFracionada")}
                options={countSelectData}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="deadline_fractional"
                type="number"
                label={getTranslation("prazoFracionada")}
              />
              <Select
                name="count_dedicated"
                label={getTranslation("contagemLotacao")}
                options={countSelectData}
                placeholder={getTranslation("selecione")}
              />
              <Input
                name="deadline_dedicated"
                type="number"
                label={getTranslation("prazoLotacao")}
              />
            </S.FormRow>
          </S.BoxContainer>
          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>

              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};

export default EditTransitTime;
