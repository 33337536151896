export const translations = {
  exibindopagina: {
    pt: 'Exibindo página ',
    en: 'Showing page ',
    de: 'Seite wird anzeigt ',
    es: 'Mostrando página ',
  },
  deUmTotalDe: {
    pt: ' de um total de ',
    en: ' of total ',
    de: ' von insgesamt  ',
    es: ' del total ',
  },
  registros: {
    pt: ' registros.',
    en: ' records.',
    de: ' Datensätze.',
    es: ' registros.',
  },
  primeira: {
    pt: 'Primeira',
    en: 'First',
    de: 'Erste',
    es: 'Primera',
  },
  ultima: {
    pt: 'Última',
    en: 'Last',
    de: 'Letzte',
    es: 'Última',
  },
};