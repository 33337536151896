export const translations = {
  obrigatorio: {
    pt: "Obrigatório",
    en: "Mandatory",
    de: "Pflichtfeld",
    es: "Obligatorio",
  },
  novoCentro: {
    pt: "Novo centro",
    en: "New center",
    de: "Neues Zentrum",
    es: "Nuevo centro",
  },
  estrangeiro: {
    pt: "Estrangeiro",
    en: "Foreign",
    de: "Ausland",
    es: "Extranjero",
  },
  nacional: {
    pt: "Nacional",
    en: "Nacional",
    de: "Inland",
    es: "Nacional",
  },
  idExterior: {
    pt: "ID Exterior",
    en: "Foreign ID",
    de: "ID Ausland",
    es: "DNI extranjero",
  },
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  selecione: {
    pt: "Selecione...",
    en: "Select...",
    de: "Auswählen...",
    es: "Seleccione...",
  },
  provincy: {
    pt: "Província",
    en: "State",
    de: "Länder",
    es: "Província",
  },
  complemento: {
    pt: "Complemento",
    en: "Complement",
    de: "Ergänzung",
    es: "Complemento",
  },
  postalCode: {
    pt: "Código postal",
    en: "Zip code",
    de: "Postleitzahl",
    es: "Código postal",
  },
  nomeFantasia: {
    pt: "Nome Fantasia",
    en: "Fantasy name",
    de: "Fantasiename",
    es: "Nombre de fantasía",
  },
  inscricaoEstatual: {
    pt: "Inscrição Estadual",
    en: "State registration",
    de: "Staatliche Registrierung",
    es: "Registro estatal",
  },
  horaCorte: {
    pt: "Hora de corte",
    en: "Cutting time",
    de: "Schnittzeit",
    es: "Tiempo de corte",
  },
  horaCorteTotal: {
    pt: "Hora de corte carga total",
    en: "Full load cut-off time",
    de: "Abschaltzeit bei Volllast",
    es: "Tiempo de corte a plena carga",
  },
  roterizacao: {
    pt: "Roteirização Automática",
    en: "Automatic Routing",
    de: "Automatisches Routing",
    es: "Enrutamiento automático",
  },
  gerencia: {
    pt: "Gerencia Cargas",
    en: "Manage Cargo",
    de: "Fracht verwalten",
    es: "Administrar carga",
  },
  inicioAgendamento: {
    pt: "Início do agendamento",
    en: "Schedule start",
    de: "Planbeginn",
    es: "Horario de inicio",
  },
  telefone: {
    pt: "Telefone",
    en: "Telephone",
    de: "Telefon",
    es: "Teléfono",
  },
  cnpj: {
    pt: "CNPJ",
    en: "CNPJ",
    de: "CNPJ",
    es: "Numero de Identificacion",
  },
  razaoSocial: {
    pt: "Razão Social",
    en: "Social Reason",
    de: "Soziale Vernunft",
    es: "Razón Social",
  },
  uf: {
    pt: "UF",
    en: "Federative unit",
    de: "Föderative Einheit",
    es: "Estado/Provincia",
  },
  cep: {
    pt: "Cep",
    en: "Zip code",
    de: "Postleitzahl",
    es: "Código postal",
  },
  lougradouro: {
    pt: "Logradouro",
    en: "Public place",
    de: "Öffentlicher Platz",
    es: "Direccion",
  },
  numero: {
    pt: "Número",
    en: "Number",
    de: "Nummer",
    es: "Número",
  },
  codigoCentro: {
    pt: "Código do Centro",
    en: "Code Center",
    de: "Zentrumscode",
    es: "Código do Centro",
  },
  bairro: {
    pt: "Bairro",
    en: "Número",
    de: "Gegend",
    es: "Barrio",
  },
  cidade: {
    pt: "Cidade",
    en: "City",
    de: "Stadt",
    es: "Ciudad",
  },
  latitude: {
    pt: "Latitude",
    en: "Latitude",
    de: "Breite",
    es: "Latitud",
  },
  longitude: {
    pt: "Longitude",
    en: "Longitude",
    de: "Längengrad",
    es: "Longitud",
  },
  email: {
    pt: "E-mail",
    en: "E-mail",
    de: "E-mail",
    es: "Correos electrónico",
  },
  limparFiltro: {
    pt: "Limpar Filtro",
    en: "Clear Filter",
    de: "Klarfilter",
    es: "Filtro limpio",
  },
  freteValor: {
    pt: "Frete Valor",
    en: "Shipping Value",
    de: "Versandwert",
    es: "Valor de envío",
  },
  fretPeso: {
    pt: "Frete Peso",
    en: "Shipping Weight",
    de: "Versandgewicht",
    es: "Peso de envío",
  },
  seguro: {
    pt: "Seguro",
    en: "Safe",
    de: "Sicher",
    es: "Seguro",
  },
  pedagio: {
    pt: "Pedágio",
    en: "Toll",
    de: "Maut",
    es: "Peaje",
  },
  tipo: {
    pt: "Tipo",
    en: "Type",
    de: "Typ",
    es: "Escribe",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  filtrar: {
    pt: "Filtrar",
    en: "Filter",
    de: "Filter",
    es: "Filtrar",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },
  cadastrar: {
    pt: "Cadastrar",
    en: "Register",
    de: "Katastern",
    es: "Register",
  },
  cnpjInvalido: {
    pt: "CNPJ Inválido",
    en: "Invalid CNPJ",
    de: "Ungültiger CNPJ",
    es: "CNPJ no válido",
  },
  emailInvalido: {
    pt: "Email inválido",
    en: "Invalid email",
    de: "Ungültige EMail",
    es: "Email inválido",
  },
  CEPnaoEncontrado: {
    pt: "CEP não encontrado",
    en: "CEP not found",
    de: "CEP nicht gefunden",
    es: "CEP no encontrado",
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
  informaçõesDeExpediente: {
    pt: "Informações de expediente:",
    en: "Office Information:",
    de: "Dienstinformationen:",
    es: "Información de oficina:",
  },
  segunda: {
    pt: "Segunda",
    en: "Monday",
    de: "Montag",
    es: "Lunes",
  },
  terca: {
    pt: "Terça",
    en: "Tuesday",
    de: "Dienstag",
    es: "Martes",
  },
  quarta: {
    pt: "Quarta",
    en: "Wednesday",
    de: "Der Mittwoch",
    es: "Miércoles",
  },
  quinta: {
    pt: "Quinta",
    en: "Thursday",
    de: "Donnerstag",
    es: "Jueves",
  },
  sexta: {
    pt: "Sexta",
    en: "Friday",
    de: "Freitag",
    es: "Viernes",
  },
  sabado: {
    pt: "Sábado",
    en: "Saturday",
    de: "Samstag",
    es: "Sábado",
  },
  domingo: {
    pt: "Domingo",
    en: "Sunday",
    de: "Sonntag",
    es: "Domingo",
  },
};
