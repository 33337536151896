import styled from "styled-components";
import { LogoutCircleR } from "@styled-icons/remix-line";
import { colors } from "styles";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 16px;
  /* border-top: 2px dotted rgba(255, 255, 255, 0.15); */
  border-bottom: 2px dotted ${colors.darkRgba15};
`;

export const Name = styled.h6`
  font-size: 18px;
  color: ${colors.deepPurple};
`;

export const Logout = styled.button.attrs({
  type: "button",
})`
  color: ${colors.orange};
  margin-left: 16px;
  transition: 300ms ease;
  :hover {
    color: ${colors.yellow};
  }
`;

export const LogoutIcon = styled(LogoutCircleR).attrs({ size: 20 })``;
