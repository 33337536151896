export const translations = {
  docs: {
    pt: "Documentos",
    en: "Documents",
    de: "Dokumente",
    es: "Documentos",
  },
  upload: {
    pt: "Upload",
    en: "Upload",
    de: "hochladen",
    es: "Subir",
  },
  categoria: {
    pt: "Categoria",
    en: "Category",
    de: "Kategorie",
    es: "Categoría",
  },
  file: {
    pt: "Arquivo",
    en: "File",
    de: "Datei",
    es: "Archivo",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
  cadastrar: {
    pt: "Cadastrar",
    en: "Register",
    de: "Registrieren",
    es: "Register",
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Entfernen",
    es: "Retirar",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Zurück",
    es: "Regresa",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "Abbrechen",
    es: "Cancelar",
  },
};
