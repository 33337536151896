import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListDocumentCategoryActions } from "store/ducks/settings/document-category";

import { IDocumentCategory } from "interfaces/document-category";

export function* listDocumentCategoryRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(
      apiGeneral.get,
      `/document-category?${queryString}`
    );
    const comboOptions = data.map((clientType: IDocumentCategory) => ({
      value: clientType.id,
      label: clientType.name,
    }));

    yield put(ListDocumentCategoryActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListDocumentCategoryActions.failure(errorMessage));
  }
}
