export const translations = {
  codigoSAP: {
    pt: 'Código SAP',
    en: 'SAP code',
    de: 'SAP-Code',
    es: 'código SAP',
  },
  estrangeiro: {
    pt: 'Estrangeiro',
    en: 'Foreign',
    de: 'Ausland',
    es: 'Extranjero',
  },
  nacional: {
    pt: 'Nacional',
    en: 'Nacional',
    de: 'Inland',
    es: 'Nacional',
  },
  idExterior: {
    pt: 'ID Exterior',
    en: 'Foreign ID',
    de: 'ID Ausland',
    es: 'DNI extranjero',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
  novaTransportadora: {
    pt: 'Nova Transportadora',
    en: 'New Carrier',
    de: 'Neuer Träger',
    es: 'Nuevo transportista',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  inscricaoEstatual: {
    pt: 'Inscrição Estadual',
    en: 'State registration',
    de: 'Staatliche Registrierung',
    es: 'Registro estatal',
  },
  telefone: {
    pt: 'Telefone',
    en: 'Telephone',
    de: 'Telefon',
    es: 'Teléfono',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'Numero de identificacion',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Firmenname',
    es: 'Razón Social',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Bundesstaat',
    es: 'Estado/Provincia',
  },
  lougradouro: {
    pt: 'Logradouro',
    en: 'Public place',
    de: 'Adresse',
  },
  numero: {
    pt: 'Número',
    en: 'Number',
    de: 'Hausnummer',
    es: 'Número',
  },
  postalCode: {
    pt: 'Código postal',
    en: 'Zip code',
    de: 'Postleitzahl',
    es: 'Código postal',
  },
  bairro: {
    pt: 'Bairro',
    en: 'Neighborhood',
    de: 'Stadtviertel',
    es: 'Barrio',
  },
  provincy: {
    pt: 'Província',
    en: 'State',
    de: 'Länder',
    es: 'Província',
  },
  gerencia: {
    pt: 'Gerencia Cargas',
    en: 'Manage Cargo',
    de: 'Ladungsmanagement',
    es: 'Administrar carga',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Ort',
    es: 'Ciudad',
  },
  latitude: {
    pt: 'Latitude',
    en: 'Latitude',
    de: 'Breitengrad',
    es: 'Latitud',
  },
  longitude: {
    pt: 'Longitude',
    en: 'Longitude',
    de: 'Längengrad',
    es: 'Longitud',
  },
  emails: {
    pt: 'E-mails',
    en: 'E-mails',
    de: 'E-mails',
    es: 'Correos electrónicos',
  },
  cep: {
    pt: 'Cep',
    en: 'Zip code',
    de: 'Postleitzahl',
    es: 'Código postal',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  freteValor: {
    pt: 'Valor de Frete',
    en: 'Shipping Value',
    de: 'Versandwert',
    es: 'Valor de envío',
  },
  fretePeso: {
    pt: 'Frete Peso',
    en: 'Shipping Weight',
    de: 'Frachtgewicht',
    es: 'Peso de envío',
  },
  seguro: {
    pt: 'Ad Valoren',
    en: 'Ad Valoren',
    de: 'Versicherung',
    es: 'Ad Valoren',
  },
  pedagio: {
    pt: 'Pedágio',
    en: 'Toll',
    de: 'Maut',
    es: 'Peaje',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Zurück',
    es: 'Regresa',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  type: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Typ',
    es: 'Tipo',
  },
  tipo_contratada: {
    pt: 'Contratada',
    en: 'Contractor',
    de: 'Angestellt',
    es: 'Contratado',
  },
  tipo_redespacho: {
    pt: 'Redespacho',
    en: 'Redispatch',
    de: 'Redispatch',
    es: 'Redispacho',
  },
  pais: {
    pt: 'País',
    en: 'Country',
    de: 'Land',
    es: 'País',
  },
  granel: {
    pt: "Carga a granel",
    en: "Bulk cargo",
    de: "Schüttgut",
    es: "Carga a granel",
  },
  obrigatorio: {
    pt: "Obrigatório",
    en: "Mandatory",
    de: "Obligatorisch",
    es: "Obligatorio",
  },
};
