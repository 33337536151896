import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import {
  PaginatePermissionsState,
  PaginatePermissionsActions,
} from "store/ducks/settings/permissions";

import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";

import * as S from "./styles";
import { MainContainer, Paginator, MenuSelector } from "components/shared";
import { GridPermissions, FilterPermissions } from "components/settings";

export const ListPermissions: React.FC = () => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);

  const [query, setQuery] = useState<Record<string, any>>({
    limit: 10,
    page: 1,
  });

  const { loading, pagination, data } = useSelector<
    RootStateOrAny,
    PaginatePermissionsState
  >((state) => state.paginatePermissions);

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const handleSubmitFilter = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        ...value,
        page: 1,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const getData = useCallback(() => {
    dispatch(PaginatePermissionsActions.request({ ...query, ...dataFilter }));
  }, [dispatch, query, dataFilter]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")} <MenuSelector page="permissions" />
          {loading && <S.LoadingPage />}
        </h1>
        <S.HeaderButtons>
          <FilterPermissions onFilter={handleSubmitFilter} />
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <GridPermissions permissions={data}  loading={loading}/>
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </MainContainer>
  );
};
