export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  duracao: {
    pt: 'Duração',
    en: 'Duration',
    de: 'Dauer',
    es: 'Duración',
  },
  peso: {
    pt: 'Peso',
    en: 'Weight',
    de: 'Gewicht',
    es: 'Peso',
  },
  paletes: {
    pt: 'Paletes',
    en: 'Pallets',
    de: 'Paletten',
    es: 'Paletas',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Werk',
    es: 'Centrar',
  },
  veiculo: {
    pt: 'Veículo',
    en: 'Vehicle',
    de: 'Fahrzeug',
    es: 'Tipo de vehiculo',
  },
  disEntrega: {
    pt: 'Dist. Entrega',
    en: 'Dist. Delivery',
    de: 'Entf. Lieferung',
    es: 'Dist. Entrega',
  },
  disMaxima: {
    pt: 'Dist. Maxima',
    en: 'Dist. maximum',
    de: 'Max. Entfernung',
    es: 'Dist. máximo',
  },
  maxEntregas: {
    pt: 'Max. Entregas',
    en: 'Max. deliveries',
    de: 'Max. Lieferungen',
    es: 'Máx. Entregas',
  },
};