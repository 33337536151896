export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoCentro: {
    pt: 'Novo centro',
    en: 'New center',
    de: 'Neues Zentrum',
    es: 'Nuevo centro',
  },
  emailInvalido: {
    pt: 'Email inválido',
    en: 'Invalid email',
    de: 'Ungültige EMail',
    es: 'Email inválido',
  },
  CEPnaoEncontrado: {
    pt: 'CEP não encontrado',
    en: 'CEP not found',
    de: 'CEP nicht gefunden',
    es: 'CEP no encontrado',
  },
  transportadoraAdicionada: {
    pt: 'Transpostadora já adicionada',
    en: 'Carrier already added',
    de: 'Mobilfunkanbieter bereits hinzugefügt',
    es: 'Transportista ya agregado',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  roterizacao: {
    pt: 'Roteirização Automática',
    en: 'Automatic Routing',
    de: 'Automatisches Routing',
    es: 'Enrutamiento automático',
  },
  gerenciaCargas: {
    pt: 'Gerencia Cargas',
    en: 'Cargo Management',
    de: 'Frachtmanagement',
    es: 'Gestión de carga',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Föderative Einheit',
    es: 'Estado/Provincia',
  },
  cep: {
    pt: 'Cep',
    en: 'Zip code',
    de: 'Postleitzahl',
    es: 'Código postal',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  cnpjInvalido: {
    pt: 'CNPJ Inválido',
    en: 'Invalid CNPJ',
    de: 'Ungültiger CNPJ',
    es: 'CNPJ no válido',
  },
  
};