import styled from 'styled-components';
import { fonts } from 'styles';
import { colors } from 'styles/colors';

export {
  Button,
  ButtonMini,
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
} from 'styles/styled-components';

export { Trash } from 'styles/styled-components/Icons';

export const Container = styled.section`
  width: 100%;

  form {
    margin-bottom: 24px;
  }

  button.add {
    height: 50px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 8px;
`;

export const Text = styled.article`
  font-size: 14px;
  color: ${colors.gray3};
  margin-bottom: 24px;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: auto 30px 30px;
  border-bottom: 2px dotted ${colors.gray2};
  font-family: ${fonts.GilroyBold};
  font-size: 12px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  align-items: center;

  &:last-child {
    /* border-bottom: none; */
    margin-bottom: 0;
  }
`;

export const ItemLabel = styled.div`
  span {
    display: inline-block;
    margin-right: 8px;
    font-family: ${fonts.GilroySemiBold};
    text-transform: uppercase;
  }
`;

export const DeleteButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.red};
`;