import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation, usePermissions } from "hooks";

import { MainContainer, Paginator } from "components/shared";
import { GridDocument, FilterDocument } from "components/Document";

import { translations } from "./translations";
import * as S from "./styles";
import {
  PaginateDocumentActions,
  PaginateDocumentState,
  DownloadDocumentActions,
  DownloadDocumentState,
} from "store/ducks/documents";

export const ListDocument: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const { hasEveryPermission } = usePermissions();

  const isAdmin = hasEveryPermission(["Administrador"]);

  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
  });

  const { data, loading, pagination } = useSelector<RootStateOrAny>(
    (state) => state.paginateDocument
  ) as PaginateDocumentState;

  const { data: dataDownload } = useSelector<RootStateOrAny>(
    (state) => state.downloadDocument
  ) as DownloadDocumentState;

  const getData = useCallback(() => {
    dispatch(PaginateDocumentActions.request({ ...query }));
  }, [dispatch, query]);

  const getDataFilter = useCallback(
    (query) => {
      dispatch(PaginateDocumentActions.request(query));
    },
    [dispatch]
  );

  const handleSubmitFilter = useCallback(
    (value) => {
      const data = {
        ...query,
        ...value,
        page: 1,
      };
      setQuery((state) => ({ ...state, ...data }));
      getDataFilter(data);
    },
    [setQuery, query, getDataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...query,
        page: value,
      };
      setQuery((state) => ({ ...state, ...data }));
      getDataFilter(data);
    },
    [setQuery, query, getDataFilter]
  );

  useEffect(() => {
      getData();
  }, [getData, ]);

  const downloadFile = useCallback(() => {
    if (!dataDownload) return false;
    const file = dataDownload.file;
    const fileName = dataDownload.file_name;

    if (!file || !fileName) {
      console.error("Arquivo ou nome do arquivo não definidos.");
      return;
    }

    fetch(file)
      .then((response) => response.blob())
      .then((blob) => {
        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    dispatch(DownloadDocumentActions.reset());
  }, [dispatch, dataDownload]);

  const getDownloadDoc = useCallback(
    (id) => {
      dispatch(DownloadDocumentActions.request(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (dataDownload) {
      downloadFile();
    }
  }, [dataDownload, downloadFile]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconFile />
          {getTranslation("docs")}

          {loading && <S.LoadingPage />}
        </h1>

        <S.HeaderButtons>
          {isAdmin && (
            <S.ButtonMini onClick={() => history.push("/docs/new")}>
              <S.IconUpload /> {getTranslation("upload")}
            </S.ButtonMini>
          )}

          <FilterDocument onFilter={handleSubmitFilter} />
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <GridDocument docs={data} handleDownload={getDownloadDoc} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </MainContainer>
  );
};
