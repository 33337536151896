import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListPermissionsActions } from "store/ducks/settings/permissions";

import { IPermission } from "interfaces/permission";

export function* listPermissionsRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/permissions?${queryString}`);
    if(query.all) {
      const comboOptions = data.map((permission: IPermission) => ({
        value: permission.id,
        label: `${permission.domain} - ${permission.name || "---"}`,
        ...permission,
      }));
      yield put(ListPermissionsActions.success(comboOptions));
      if (onSuccess) onSuccess(comboOptions);
    } else {
      const { permissions } = data;
      const comboOptions = permissions.map((permission: IPermission) => ({
        value: permission.id,
        label: `${permission.domain} - ${permission.name || "---"}`,
        ...permission,
      }));
      yield put(ListPermissionsActions.success(comboOptions));
      if (onSuccess) onSuccess(comboOptions);
    }
    
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListPermissionsActions.failure(errorMessage));
  }
}
