export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoProduto: {
    pt: 'Novo Produto',
    en: 'New product',
    de: 'Neues Produkt',
    es: 'Nuevo Producto',
  },
};