import { call, put } from 'redux-saga/effects';

import { apiGeneral, notify } from 'services';
import { IUpdateRequest } from 'interfaces/update-duck';
import { requestErrorHandler } from 'utils';
import { DeleteClientCodeActions } from 'store/ducks/settings/client-codes';

export function* deleteClientCodeRequest(action: any) {
  try {
    const { id, onSuccess } = action as IUpdateRequest;
    const { data } = yield call(apiGeneral.delete, `/client-codes/${id}`);

    yield put(DeleteClientCodeActions.success(data));
    if (onSuccess) onSuccess(data);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify('error', errorMessage);
    yield put(DeleteClientCodeActions.failure(errorMessage));
  }
}
