export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novaLinha: {
    pt: 'Nova Business Line',
    en: 'New Business Line',
    de: 'Neue Geschäftslinie',
    es: 'Nueva Línea de Negocio',
  },

};