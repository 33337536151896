export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoAlerta: {
    pt: 'Novo Alerta',
    en: 'New Alert',
    de: 'Neue Benachrichtigung',
    es: 'Nueva alerta',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Entfernen',
    es: 'Retirar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  linhaDenegocio: {
    pt: 'Deseja realmente remover essa linha de negócio?',
    en: 'Do you really want to remove this line of business?',
    de: 'Geschäftslinie löschen?',
    es: '¿Realmente desea eliminar esta línea de negocio?',
  },
  editarLinha: {
    pt: 'Editar Business Line',
    en: 'Edit Business Line',
    de: 'Geschäftslinie bearbeiten',
    es: 'Editar línea de negocio',
  },
  setorAtividade: {
    pt: 'Setor de Atividade Divisão',
    en: 'Activity Sector Division',
    de: 'Branche Abteilung',
    es: 'División de Sector de Actividad',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  unidade: {
    pt: 'Unidade',
    en: 'Unit',
    de: 'Einheit',
    es: 'Unidad',
  },
};