export const translations = {
  nome: {
    pt: "Nome do documento",
    en: "Document name",
    de: "Dokumentname",
    es: "Nombre del documento",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
  categoria: {
    pt: "Categoria",
    en: "Category",
    de: "Kategorie",
    es: "Categoría",
  },
  fechar: {
    pt: "Fechar",
    en: "Close",
    de: "Schließen",
    es: "Cerrar",
  },
  filtrar: {
    pt: "Filtrar",
    en: "Filter",
    de: "Filtern",
    es: "Filtrar",
  },
  limparFiltro: {
    pt: "Limpar Filtro",
    en: "Clear Filter",
    de: "Filter zurücksetzen",
    es: "Filtro limpio",
  },
};
