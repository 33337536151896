import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
// componentes
import { useTranslation, useValidation } from 'hooks';
import { Modal } from 'components/shared';
import { Input, Select } from 'components/shared/Form';

// saga
import { ListModulesActions, ListModulesState } from 'store/ducks/modules';
import {
  PaginateCarrierContactActions,
  PaginateCarrierContactState,
  CreateCarrierContactActions,
  CreateCarrierContactState,
  UpdateCarrierContactActions,
  UpdateCarrierContactState,
  DeleteManyCarrierContactActions,
  DeleteManyCarrierContactState,
} from 'store/ducks/settings/carrier-contact';

import { translations } from './translations';
import * as S from './styles';

interface IFilterProps {
  general_carrier_id: number | string;
}
interface IEditContact {
  id: number | string;
  edit: boolean;
}

export const ContactCarriers: React.FC<IFilterProps> = ({
  general_carrier_id,
}) => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  const { handleFormErrors } = useValidation();
  const [editMode, setEditMode] = useState<IEditContact>();
  const [idsDelete, setIdsDelete] = useState<Array<number>>([]);
  const [emailsDelete, setEmailsDelete] = useState<Array<string>>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const formContactRef = useRef<FormHandles>(null);
  const formUpdateContactRef = useRef<FormHandles>(null);

  const { data, loading } = useSelector<RootStateOrAny>(
    (state) => state.paginateCarrierContact
  ) as PaginateCarrierContactState;

  const { loading: loadingCreate } = useSelector<RootStateOrAny>(
    (state) => state.createCarrierContact
  ) as CreateCarrierContactState;

  const { loading: loadingUpdate } = useSelector<RootStateOrAny>(
    (state) => state.updateCarrierContact
  ) as UpdateCarrierContactState;

  const { loading: loadingDelete } = useSelector<RootStateOrAny>(
    (state) => state.deleteManyCarrierContact
  ) as DeleteManyCarrierContactState;

  const { data: dataModules, loading: loadingModules } =
    useSelector<RootStateOrAny>(
      (state) => state.listModules
    ) as ListModulesState;

  const getListModules = useCallback(() => {
    dispatch(ListModulesActions.request());
  }, [dispatch]);

  const getData = useCallback(() => {
    dispatch(PaginateCarrierContactActions.request({ general_carrier_id }));
  }, [dispatch, general_carrier_id]);

  const onSuccess = useCallback(() => {
    setEditMode({ id: '', edit: false });
    setIdsDelete([]);
    setEmailsDelete([]);
    setModalOpen(false);
    getData();
  }, [getData, setEditMode, setIdsDelete, setEmailsDelete, setModalOpen]);

  const deleteContact = useCallback(() => {
    dispatch(
      DeleteManyCarrierContactActions.request({ data: idsDelete }, onSuccess)
    );
  }, [dispatch, onSuccess, idsDelete]);

  const handleSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        formContactRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.array()
            .transform(function (value, originalValue) {
              if (this.isType(value) && value !== null) {
                return value;
              }
              return originalValue ? originalValue.split(/[\s;]+/) : [];
            })
            .of(
              Yup.string().email(
                ({ value }) => `"${value}" não é um e-mail válido`
              )
            )
            .min(1, getTranslation('obrigatorio')),
          general_module_id: Yup.string().required(
            getTranslation('obrigatorio')
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (editMode?.edit) {
          dispatch(
            UpdateCarrierContactActions.request(editMode.id, data, onSuccess)
          );
          return;
        }

        const { general_module_id } = data;
        const listEmail = data.email.split(';');
        const newData = listEmail.map((email: string) => ({
          email,
          general_module_id,
          general_carrier_id,
        }));

        dispatch(
          CreateCarrierContactActions.request({ data: newData }, onSuccess)
        );
      } catch (error) {
        if (editMode?.edit) {
          handleFormErrors(error, formUpdateContactRef);
          return;
        }

        handleFormErrors(error, formContactRef);
      }
    },
    [
      general_carrier_id,
      dispatch,
      handleFormErrors,
      onSuccess,
      getTranslation,
      editMode,
    ]
  );

  const handleDelete = useCallback(
    (id) => {
      const isIdPresent = idsDelete.includes(id);
      let ids: Array<number> = [];
      if (isIdPresent) {
        ids = idsDelete.filter((itemId) => itemId !== id);
      } else {
        ids = [...idsDelete, id];
      }

      const emails: Array<string> = [];
      data.map((contact: any) => {
        if (ids.includes(contact.id)) {
          emails.push(contact.email);
        }
        return 0;
      });

      setIdsDelete(ids);
      setEmailsDelete(emails);
    },
    [idsDelete, setIdsDelete, setEmailsDelete, data]
  );

  useEffect(() => getListModules(), [getListModules]);
  useEffect(() => getData(), [getData]);

  return (
    <S.Container>
      <S.Title>
        {getTranslation('titulo')}
        {loading && <S.Loading />}
      </S.Title>
      <S.BoxContainer>
        <Form ref={formContactRef} onSubmit={handleSubmit}>
          <S.FormRow>
            <Input
              name="email"
              label={getTranslation('email')}
              placeholder={getTranslation('utilizarSeparador')}
            />

            <Select
              name="general_module_id"
              label={getTranslation('modulos')}
              isLoading={loadingModules}
              isDisabled={loadingModules}
              options={dataModules}
              placeholder={getTranslation('selecioneModulo')}
            />
            <S.ButtonContainerCreate>
              <S.ButtonMini
                type="button"
                className="add"
                onClick={() => formContactRef.current?.submitForm()}
                disabled={editMode?.edit}
              >
                {loadingCreate ? <S.Loading /> : getTranslation('adicionar')}
              </S.ButtonMini>
            </S.ButtonContainerCreate>
          </S.FormRow>
        </Form>

        <S.GridContainer>
          {data &&
            data.map((item: any) => {
              return (
                <S.ItemContainer key={item.id}>
                  {editMode?.edit && editMode?.id === item.id ? (
                    <S.ItemContent edit={true}>
                      <Form ref={formUpdateContactRef} onSubmit={handleSubmit}>
                        <S.FormRow>
                          <Input
                            name="email"
                            placeholder={getTranslation('email')}
                            defaultValue={item.email}
                          />

                          <Select
                            name="general_module_id"
                            isLoading={loadingModules}
                            isDisabled={loadingModules}
                            options={dataModules}
                            placeholder={getTranslation('selecioneModulo')}
                            defaultOptions={dataModules.find(
                              (el: any) => el.value === item.general_module_id
                            )}
                          />
                          <S.ButtonMini
                            type="button"
                            btStyle="cancel"
                            className="cancel"
                            onClick={() => {
                              setEditMode({ id: '', edit: false });
                            }}
                          >
                            {getTranslation('cancelar')}
                          </S.ButtonMini>

                          <S.ButtonMini
                            type="button"
                            className="add"
                            onClick={() =>
                              formUpdateContactRef.current?.submitForm()
                            }
                          >
                            {loadingUpdate ? (
                              <S.Loading />
                            ) : (
                              getTranslation('salvar')
                            )}
                          </S.ButtonMini>
                        </S.FormRow>
                      </Form>
                    </S.ItemContent>
                  ) : (
                    <S.ItemContent active={idsDelete.includes(item.id)}>
                      <S.ItemValue>{item.email}</S.ItemValue>
                      <S.ItemValue>{item.module.name}</S.ItemValue>

                      <S.BoxButtonsActions>
                        <S.ButtonIcon
                          onClick={() => {
                            // handleEdit(item.id);
                            setEditMode({ id: item.id, edit: true });
                          }}
                          disabled={editMode?.edit}
                        >
                          <S.IconEdit
                            disabled={
                              editMode?.edit || idsDelete.includes(item.id)
                            }
                          />
                        </S.ButtonIcon>

                        <S.ButtonIcon
                          onClick={() => handleDelete(item.id)}
                          disabled={editMode?.edit}
                        >
                          <S.IconDelete disabled={editMode?.edit} />
                        </S.ButtonIcon>
                      </S.BoxButtonsActions>
                    </S.ItemContent>
                  )}
                </S.ItemContainer>
              );
            })}

          <S.FloatBar active={!!idsDelete.length}>
            <S.FloatContainer>
              <S.TextSelected>{`${getTranslation('selecaoInicio')}  ${
                idsDelete.length
              }  ${getTranslation('selecaoFinal')}`}</S.TextSelected>
              <S.ButtonsWrapper>
                <S.ButtonMini
                  btStyle="cancel"
                  onClick={() => {
                    setIdsDelete([]);
                  }}
                >
                  {getTranslation('cancelar')}
                </S.ButtonMini>
                <S.ButtonMini
                  onClick={() => {
                    setModalOpen(() => true);
                  }}
                >
                  {getTranslation('confirmar')}
                </S.ButtonMini>
              </S.ButtonsWrapper>
            </S.FloatContainer>
          </S.FloatBar>
        </S.GridContainer>
      </S.BoxContainer>

      <Modal isOpen={modalOpen}>
        <S.ContainerModal>
          <S.HeaderModal>
            <S.Title>{getTranslation('tituloDeletar')}</S.Title>
          </S.HeaderModal>
          <S.BodyModal>
            <S.TextModal>
              {idsDelete.length === 1
                ? getTranslation('descricaoDeletar')
                : getTranslation('descricaoDeletarPlural')}
            </S.TextModal>
            <S.TextModal>
              <b>{emailsDelete.join(', ')}</b>
            </S.TextModal>
          </S.BodyModal>

          <S.ButtonsWrapper>
            <S.Button
              btStyle="cancel"
              onClick={() => {
                setModalOpen(() => false);
              }}
              type="button"
            >
              {getTranslation('cancelar')}
            </S.Button>
            <S.Button onClick={() => deleteContact()}>
              {loadingDelete ? <S.Loading /> : getTranslation('confirmar')}
            </S.Button>
          </S.ButtonsWrapper>
        </S.ContainerModal>
      </Modal>
    </S.Container>
  );
};
