import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import { IState, ISuccessAction, IFailureAction } from 'interfaces/create-duck';

const { Types, Creators } = createActions(
  {
    request: ['postData', 'onSuccess', 'onFailure'],
    success: [],
    failure: ['error'],
    reset: [],
  },
  { prefix: 'CREATE_CLIENT_CODE_' }
);

export interface CreateClientCodeState extends IState {}

const INITIAL_STATE: CreateClientCodeState = {
  loading: false,
  error: null,
};

const request = (state: CreateClientCodeState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: CreateClientCodeState, action: ISuccessAction) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: CreateClientCodeState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const createClientCode = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateClientCodeTypes = Types;
export const CreateClientCodeActions = Creators;
