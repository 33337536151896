import styled, { css } from "styled-components";
import { fonts } from "styles";
import { colors } from "styles/colors";
import { Trash, Edit } from "styles/styled-components";

export {
  Button,
  ButtonMini,
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
} from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  form {
    margin-bottom: 24px;
  }

  button.add,
  button.cancel {
    height: 50px;
  }

  
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
  
`;

export const Title = styled.h2`
  margin-bottom: 8px;
`;

export const Text = styled.article`
  font-size: 14px;
  color: ${colors.gray3};
  margin-bottom: 24px;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: auto 24px;
  border-bottom: 2px dotted ${colors.gray2};
  font-family: ${fonts.GilroyBold};
  font-size: 12px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  align-items: center;

  &:last-child {
    /* border-bottom: none; */
    margin-bottom: 0;
  }
`;

export const ItemLabel = styled.div`
  span {
    display: inline-block;
    margin-right: 8px;
    font-family: ${fonts.GilroySemiBold};
    text-transform: uppercase;
  }
`;

export const DeleteButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.red};
`;

export const ButtonContainerCreate = styled.div`
  padding-top: 25px;
`;

// Grid
const template = css`
  grid-template-columns: repeat(2, 1fr) auto;
`;
export const GridContainer = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const GridHeader = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  ${template}
  column-gap: 16px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const Label = styled.span`
  font-family: ${fonts.GilroyBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray3};
`;
export const LabelGhost = styled.span`
  width: 70px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`;

export const ItemContent = styled.a<{ edit?: boolean; active?: boolean }>`
  width: 100%;
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.15);
  transition: 200ms ease;

  ${({ edit }) =>
    !edit &&
    css`
      display: grid;
      align-items: center;
      ${template}
      column-gap: 16px;
    `}

  ${({ active }) =>
    active &&
    css`
      transform: scale(1.01);
    `}


  :hover {
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
  }
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray4};
`;

export const BoxButtonsActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70px;
`;

export const ButtonIcon = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconDelete = styled(Trash).attrs({ size: 24 })<{
  disabled?: boolean;
}>`
  color: ${({ disabled }) => (!disabled ? colors.red : colors.gray3)};
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
`;

export const IconEdit = styled(Edit).attrs({ size: 24 })<{
  disabled?: boolean;
}>`
  color: ${({ disabled }) => (!disabled ? colors.green : colors.gray3)};
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
`;
export const FloatBar = styled.div<{ active: boolean }>`
  width: 100%;
  position: fixed;
  bottom: -100px;
  left: 0;
  padding-left: 252px;
  z-index: 99;
  transition: 300ms ease;

  ${({ active }) =>
    active &&
    css`
      bottom: 0;
    `}
`;

export const FloatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: ${colors.gray3};
  padding: 16px 32px;
`;

export const TextSelected = styled.article`
  display: flex;
  align-items: center;
  color: white;
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

// Modal

export const ContainerModal = styled.div`
  display: flex;
  width: 100%;
  max-width: 680px;
  max-height: 90vh;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 40px;
  margin: 32px 0;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 24px;
  border-bottom: 1px ${colors.line} solid;
`;
export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${colors.line} solid;
`;

export const TextModal = styled.article`
  font-size: 14px;
  color: ${colors.gray3};
  margin-bottom: 10px;
`;
