import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';

import { LoginRoutes } from './login';
import { DocumentRoutes } from './document';
import { Main } from 'pages/Main';
import {
  Distribution,
  Scheduling,
  CheckFreights,
  Billing,
  FinancialImpact,
  GhgProtocol,
} from 'pages/ControlTower';
import { SettingsRoutes } from './settings';

export const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <PrivateRoute path="/" exact component={Main} />
        <PrivateRoute path="/base/base" exact component={Main} />
        <PrivateRoute
          path="/control-tower/ghg-protocol"
          exact
          component={GhgProtocol}
        />
        <PrivateRoute
          path="/control-tower/distribution"
          exact
          component={Distribution}
        />
        <PrivateRoute
          path="/control-tower/scheduling"
          exact
          component={Scheduling}
        />
        <PrivateRoute
          path="/control-tower/check-freights"
          exact
          component={CheckFreights}
        />
        <PrivateRoute path="/control-tower/billing" exact component={Billing} />
        <PrivateRoute
          path="/control-tower/financial-impact"
          exact
          component={FinancialImpact}
        />
      </Switch>
      <LoginRoutes />
      <SettingsRoutes />
      <DocumentRoutes />
    </>
  );
};
