import React from "react";
import { useDispatch } from "react-redux";
import * as S from "./styles";
import { TransitsAr } from "interfaces/transit-time-ar";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import {DeleteTransitTimeArActions} from "store/ducks/settings/transit-time-ar";
import { useHistory } from "react-router-dom";

interface IGridTransitTimesProps {
  transits: TransitsAr[] | Record<string, any>[];
  loading: boolean;
  onDelete: any;
}

interface ITransitTimeProps {
  transit: TransitsAr | Record<string, any>;
  onDelete: any;
}

const Item: React.FC<ITransitTimeProps> = ({ transit, onDelete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const handleDelete = async (transit: any) => {
    dispatch(DeleteTransitTimeArActions.request(transit.id, onDelete));
  };

  return (
    <S.ItemContainer>
      <S.ItemContent onClick={()=>history.push(`/settings/transit-time-ar/${transit.id}`)}>
        <S.ItemValue>{transit?.carrier?.cnpj || "--"}</S.ItemValue>
        <S.ItemValue>{transit?.carrier?.trade_name || "--"}</S.ItemValue>
        <S.ItemValue>{transit?.zipcode_origin || "--"}</S.ItemValue>
        <S.ItemValue>{transit?.zipcode_destiny || "--"}</S.ItemValue>
        <S.ItemValue>{transit?.deadline_dedicated || "--"}</S.ItemValue>
        <S.ItemValue>{transit?.deadline_fractional || "--"}</S.ItemValue>
        <S.ItemValue>
          {transit?.weight
            ? transit?.weight.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })
            : "--"}
        </S.ItemValue>
      </S.ItemContent>
        <S.Button
          btStyle="danger"
          type="button"
          onClick={() => handleDelete(transit)}
        >
          {getTranslation("deletar")}
        </S.Button>
    </S.ItemContainer>
  );
};

export const GridTransitTimeAr: React.FC<IGridTransitTimesProps> = ({
  transits = [],
  loading = false,
  onDelete,
}) => {
  const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      {loading ? (
        <S.Loading />
      ) : (
        <>
          {transits.length > 0 && (
            <>
              <S.Header>
                <S.Label>{getTranslation("cnpj")}</S.Label>
                <S.Label>{getTranslation("transportadora")}</S.Label>
                <S.Label>{getTranslation("origem")}</S.Label>
                <S.Label>{getTranslation("destino")}</S.Label>
                <S.Label>{getTranslation("prazoLotacao")}</S.Label>
                <S.Label>{getTranslation("prazoFracionado")}</S.Label>
                <S.Label>{getTranslation("peso")}</S.Label>
                <S.Label></S.Label>
              </S.Header>
              {transits.map((transit) => (
                <Item transit={transit} key={transit.id} onDelete={onDelete} />
              ))}
            </>
          )}
        </>
      )}
    </S.Container>
  );
};
