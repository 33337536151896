export const translations = {
  docs: {
    pt: "Documento",
    en: "Document",
    de: "Dokumente",
    es: "Documento",
  },
  editar: {
    pt: "Editar",
    en: "Edit",
    de: "hochladen",
    es: "Subir",
  },
  categoria: {
    pt: "Categoria",
    en: "Category",
    de: "Kategorie",
    es: "Categoría",
  },
  file: {
    pt: "Arquivo",
    en: "File",
    de: "Datei",
    es: "Archivo",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
  salvar: {
    pt: "Salvar",
    en: "Save",
    de: "Speichern",
    es: "Guardar",
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Entfernen",
    es: "Retirar",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Zurück",
    es: "Regresa",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "Abbrechen",
    es: "Cancelar",
  },
  removerTitulo: {
    pt: "Remover documento",
    en: "Remove document",
    de: "Dokument entfernen",
    es: "Eliminar documento",
  },
  removerDescricao: {
    pt: "Tem certeza de que deseja este documento?",
    en: "Are you sure you want this document?",
    de: "Sind Sie sicher, dass Sie dieses Dokument möchten?",
    es: "¿Está seguro de que desea este documento?",
  },
};
