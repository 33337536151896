export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoTipo: {
    pt: 'Novo Tipo de Produto',
    en: 'New Product Type',
    de: 'Neuer Produkttyp',
    es: 'Nuevo tipo de producto',
  },
};