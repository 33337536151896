import styled from 'styled-components';
import { colors } from 'styles/colors';
export { Edit } from 'styles/styled-components/Icons';
export { FormRow, Button, Loading, ButtonMini } from "styles/styled-components";

export const EditButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.darkGreen};
`;

//MODAL
export const ModalContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  max-height: 90vh;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 40px;
  margin: 32px 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${colors.line} solid;
`;

export const ModalTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
  margin-left: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-left: none;
  border-right: none;

  button {
    margin-right: 16px;
  }

  button:last-child {
    margin-right: 0;
  }
`;