import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { IPermission } from "interfaces/permission";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";
interface GridPermissionsProps {
  permissions: IPermission[] | Record<string, any>[];
  loading: boolean;
}

interface PermissionProps {
  permission: IPermission | Record<string, any>;
}

const Item: React.FC<PermissionProps> = ({ permission }) => {
  const history = useHistory();
  const { checkUserPermission } = usePermissions();
  const havePermission = checkUserPermission("editPermision");
  return (
    <S.ItemContainer
      onClick={() =>
        havePermission && history.push(`/settings/permissions/${permission.id}`)
      }
    >
      <S.ItemContent pointer={havePermission}>
        <S.ItemValue>{permission.id}</S.ItemValue>
        <S.ItemValue>{permission.name || "--"}</S.ItemValue>
        <S.ItemValue>{permission.description || "--"}</S.ItemValue>
        <S.ItemValue>{permission.module.name || "--"}</S.ItemValue>
        <S.ItemValue>{permission.domain || "--"}</S.ItemValue>
        {havePermission && (
          <S.ButtonDetail>
            <S.IconDetail />
          </S.ButtonDetail>
        )}
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridPermissions: React.FC<GridPermissionsProps> = ({
  permissions = [],
  loading = false,
}) => {
  const { getTranslation } = useTranslation(translations);

  return (
    <S.Container>
      {loading ? (
        <S.Loading />
      ) : (
        <>
          <S.Header>
            <S.Label>ID</S.Label>
            <S.Label>{getTranslation("nome")}</S.Label>
            <S.Label>{getTranslation("descricao")}</S.Label>
            <S.Label>{getTranslation("modulo")}</S.Label>
            <S.Label>{getTranslation("grupo")}</S.Label>
            <S.Label>
              <S.Ghost></S.Ghost>
            </S.Label>
          </S.Header>
          {permissions.length &&
            permissions.map((permission) => (
              <Item permission={permission} key={permission.id} />
            ))}
        </>
      )}
    </S.Container>
  );
};
