import { useSelector, RootStateOrAny } from "react-redux";
import { AuthState } from "store/ducks/auth";

export const usePermissions = () => {
  const { data: userData, loggedIn } = useSelector<RootStateOrAny>(
    (state) => state?.auth
  ) as AuthState;
  return {
    hasAnyPermission(permissions: string[] = []) {
      if (!loggedIn) return false;
      return permissions.some((p) => userData?.roles.includes(p));
    },
    hasEveryPermission(permissions: string[] = []) {
      if (!loggedIn) return false;
      return permissions.every((p) => userData?.roles.includes(p));
    },

    checkUserPermission(acceptedPermission: string) {
      if (!loggedIn || !userData) return false;
      if (!acceptedPermission) return false;

      if (acceptedPermission.includes("all")) return true;

      const { permissions, country } = userData;

      if (!permissions) return false;
      if (!country.length) return false;

      // Encontra as permissões correspondentes com base no slug (acceptedPermission)
      const findPermissions = permissions.find(
        (permission) => permission.slug === acceptedPermission
      );

      // Verifica se encontou alguma permissão e se o país está incluído no array de países permitidos

      const checkCountry = findPermissions?.countries.some((countryID) => {
        return country.some((Country) => Country.id === countryID);
      });

      // Retorna verdadeiro se as permissões forem encontradas e o país estiver permitido
      if (findPermissions && checkCountry) return true;

      return false;
    },

    checkUserEveryPermission(acceptedPermissions: string[]) {
      if (!loggedIn || !userData) return false;

      if (acceptedPermissions.includes("all")) return true;

      const { permissions, country } = userData;

      if (!permissions) return false;
      if (!country.length) return false;

      // Verifica se todas as permissões são encontradas e se o país está incluído no array de países permitidos para cada permissão
      for (const acceptedPermission of acceptedPermissions) {
        const findPermission = permissions.find(
          (permission) => permission.slug === acceptedPermission
        );

        const checkCountry = findPermission?.countries.some((countryID) => {
          return country.some((Country) => Country.id === countryID);
        });

        if (!findPermission || !checkCountry) {
          return false;
        }
      }

      return true;
    },

    checkUserAnyPermission(acceptedPermissions: string[]) {
      if (!loggedIn || !userData) return false;

      if (acceptedPermissions.includes("all")) return true;

      const { permissions, country } = userData;

      if (!permissions) return false;
      if (!country.length) return false;

      // Verifica se todas as permissões são encontradas e se o país está incluído no array de países permitidos para cada permissão
      for (const acceptedPermission of acceptedPermissions) {
        const findPermission = permissions.find(
          (permission) => permission.slug === acceptedPermission
        );

        const checkCountry = findPermission?.countries.some((countryID) => {
          return country.some((Country) => Country.id === countryID);
        });

        if (findPermission && checkCountry) {
          return true;
        }
      }

      return false;
    },

    checkModulePermission(module_id: number) {
      if (!loggedIn || !userData) return false;
      const { permissions, country } = userData;

      if (!permissions) return false;
      if (!country.length) return false;

      // Verifica se tem ao menos uma permissão do modulo e se é do mesmo pais
      const generalModuleIdExistente = permissions.some(
        (el) =>
          el.general_module_id === module_id &&
          el.countries.some((countryID) => {
            return country.some((Country) => Country.id === countryID);
          })
      );

      if (generalModuleIdExistente) {
        return true;
      }
      return false;
    },
  };
};
