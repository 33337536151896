import styled from "styled-components";
import {
  Loading,
  File,
  CloudUpload,
  ArrowNarrowLeft,
} from "styles/styled-components";

export {
  PageContainer,
  PageHeader,
  PageContent,
  Button,
  ButtonMini,
  BoxContainer,
  FormFooter,
  FormRow,
  Loading,
} from "styles/styled-components";

export const IconFile = styled(File).attrs({ size: 24 })``;
export const IconUpload = styled(CloudUpload).attrs({ size: 24 })`
  margin-right: 8px;
`;

export const IconArrowLeft = styled(ArrowNarrowLeft).attrs({ size: 20 })`
  margin-right: 8px;
`;

export const LoadingPage = styled(Loading)`
  margin-left: 16px;
`;

export const HeaderButtons = styled.div`
  display: flex;
  button {
    &:first-child {
      margin-right: 24px;
    }
  }
`;
