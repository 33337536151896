export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  transitTime: {
    pt: "Novo Transito",
    en: "New Transit",
    de: "Neues Produkt",
    es: "Nuevo Transito",
  },
 
};
