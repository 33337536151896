import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { IZipcodeInternacional } from "interfaces/zipcode-internacional";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";

interface IGridCarriersProps {
  zipcodes: IZipcodeInternacional[] | Record<string, any>[];
}

interface ItemProps {
  zipcode: IZipcodeInternacional | Record<string, any>;
}

const Item: React.FC<ItemProps> = ({ zipcode }) => {
  const history = useHistory();
  const { checkUserPermission } = usePermissions();
  const havePermission = checkUserPermission("editZipcodeInternacional");
  return (
    <S.ItemContainer
      onClick={() =>
        havePermission && history.push(`/settings/zipcode-internacional/${zipcode.id}`)
      }
    >
      <S.ItemContent pointer={havePermission}>
        <S.ItemValue>{zipcode.id}</S.ItemValue>
        <S.ItemValue>{zipcode.zipcode || '-'}</S.ItemValue>
        <S.ItemValue>{zipcode.city || '-'}</S.ItemValue>
        <S.ItemValue>{zipcode.state || '-'}</S.ItemValue>
        <S.ItemValue>{zipcode.neighborhood || '-'}</S.ItemValue>
        <S.ItemValue>{zipcode.street || '-'}</S.ItemValue>

        {havePermission && (
          <S.ButtonDetail>
            <S.IconDetail />
          </S.ButtonDetail>
        )}
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridZipcodeInternacional: React.FC<IGridCarriersProps> = ({
  zipcodes = [],
}) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation("codigo")}</S.Label>
        <S.Label>{getTranslation("zipcode")}</S.Label>
        <S.Label>{getTranslation("city")}</S.Label>
        <S.Label>{getTranslation("state")}</S.Label>
        <S.Label>{getTranslation("neighborhood")}</S.Label>
        <S.Label>{getTranslation("street")}</S.Label>
      </S.Header>
      {zipcodes.length > 0 &&
        zipcodes.map((zipcode) => <Item zipcode={zipcode} key={zipcode.id} />)}
    </S.Container>
  );
};
