import React from "react";
import { BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import "./config/ReactotronConfig";
import GlobalStyle from "./styles/global";
import { persistor } from "./store";
import { Routes } from "routes";
import Warden from "Warden";
toast.configure();

const App: React.FC = () => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Warden />
      <BrowserRouter basename="/base">
        <GlobalStyle />
        <Routes />
      </BrowserRouter>
    </PersistGate>
  );
};

export default App;
