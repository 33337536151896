import { call, put } from "redux-saga/effects";
import { apiGeneral, notify, queryBuilder } from "services";
import { IListRequest } from "interfaces/list-duck";
import { requestErrorHandler } from "utils";
import { ListCountriesActions } from "store/ducks/country";

import { ICountryOptions } from "interfaces/country";

export function* listCountriesRequest(action: any) {
  try {
    const { query = {}, onSuccess } = action as IListRequest;
    const queryString = queryBuilder(query);
    const { data } = yield call(apiGeneral.get, `/countries?${queryString}`);
    let comboOptions = [];
    if (!!data.length) {
      comboOptions = data?.map((country: ICountryOptions) => ({
        value: country.id,
        label: country.description,
        id: country.id,
        code: country.code,
      }));
    }

    yield put(ListCountriesActions.success(comboOptions));
    if (onSuccess) onSuccess(comboOptions);
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(ListCountriesActions.failure(errorMessage));
  }
}
