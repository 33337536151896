import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Input, CheckboxInput } from "components/shared/Form";
import { MainContainer } from "components/shared";
import { useValidation, useTranslation } from "hooks";
import { translations } from "./translations";
import {
  FetchPermissionActions,
  FetchPermissionState,
  UpdatePermissionActions,
  UpdatePermissionState,
} from "store/ducks/settings/permissions";
import { ListCountriesActions, ListCountriesState } from "store/ducks/country";

import * as S from "./styles";

interface IParams {
  id: string;
}

export const EditPermission: React.FC = () => {
  const { id } = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);

  const [defaultCountries, setDefaultCountries] = useState([]);

  const { data: dataCountries } = useSelector<
    RootStateOrAny,
    ListCountriesState
  >((state) => state.listCountries);

  const { loading } = useSelector<RootStateOrAny, FetchPermissionState>(
    (state) => state.fetchPermission
  );

  const { loading: loadingUpdate } = useSelector<RootStateOrAny>(
    (state) => state.updatePermission
  ) as UpdatePermissionState;

  const onSuccess = useCallback(() => {
    history.push("/settings/permissions");
  }, [history]);

  const onFetchSuccess = useCallback((data) => {
    if (formRef.current) {
      data.general_module_id = data.module.name;
      formRef.current.setData(data);

      const countries = data.countries.map((el: any) => ({
        id: el.id,
        value: el.id,
        label: el.description,
      }));
      setDefaultCountries(countries);
    }
  }, []);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        const { name, description, domain } = data;
        delete data.general_module_id;
        delete data.description;
        delete data.domain;
        delete data.name;

        const countriesIds = Object.values(data)
          .flat()
          .map((countryId) => countryId);

        formRef.current?.setErrors({});

        const newData = {
          name,
          description,
          domain,
          countriesIds,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required("Obrigatório"),
        });

        await schema.validate(newData, {
          abortEarly: false,
        });

        dispatch(UpdatePermissionActions.request(id, newData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess, id]
  );

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(FetchPermissionActions.request(id, onFetchSuccess));
    }
  }, [dispatch, id, onFetchSuccess]);

  const getCountries = useCallback(() => {
    dispatch(ListCountriesActions.request({ all: true, permission: true }));
  }, [dispatch]);

  useEffect(() => getCountries(), [getCountries]);
  useEffect(() => fetchData(), [fetchData]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}
          <span>{getTranslation("editarPermissao")}</span>
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input name="name" label={getTranslation("nome")} />
              <Input name="description" label={getTranslation("descricao")} />
            </S.FormRow>
            <S.FormRow>
              <Input name="domain" label={getTranslation("grupo")} />
              <Input
                name="general_module_id"
                label={getTranslation("modulo")}
                disabled
              />
            </S.FormRow>

            <S.Title>{getTranslation("permissaoPorPais")}</S.Title>
            <S.CheckContainer>
              
              <CheckboxInput
                options={dataCountries}
                defaultOptions={defaultCountries}
                fixWidth="185px"
                name={"country"}
                label={getTranslation("paises")}
              />
            </S.CheckContainer>
          </S.BoxContainer>

          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button type="submit">
                {loading || loadingUpdate ? (
                  <S.Loading />
                ) : (
                  getTranslation("salvar")
                )}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
