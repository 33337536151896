import styled, { css } from "styled-components";
import { ChevronRightCircle } from "@styled-icons/boxicons-solid";
import { fonts, colors } from "styles";
import { CloudDownload } from "styles/styled-components";
export { ButtonMini } from "styles/styled-components";

const templateGrid = css`
  grid-template-columns: 350px 250px auto 100px;
`;
export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Header = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  ${templateGrid}
  column-gap: 16px;
  margin-bottom: 16px;
  padding: 0 16px;
`;

export const Label = styled.span`
  font-family: ${fonts.GilroyBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray3};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

export const ItemContent = styled.a`
  width: 100%;
  display: grid;
  align-items: center;
  ${templateGrid}
  column-gap: 16px;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 200ms ease;

  :hover {
    transform: scale(1.01);
  }
`;

export const ItemValue = styled.span`
  font-family: ${fonts.GilroySemiBold};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.gray4};
`;

export const IconDetail = styled(ChevronRightCircle).attrs({ size: 20 })`
  color: ${colors.gray3};
`;
export const IconDownload = styled(CloudDownload).attrs({ size: 24 })`
  color: ${colors.gray3};
`;

export const ButtonDetail = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ButtonContainer = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content:space-around;
`;
