export const translations = {
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'id',
    es: 'Id',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
};