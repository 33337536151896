export const translations = {
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
  modulos: {
    pt: 'Modulos',
    en: 'Modules',
    de: 'Module',
    es: 'Modulos',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Schließen',
    es: 'Cerrar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  setor: {
    pt: 'Setor de atividade divisão',
    en: 'Division of activity',
    de: 'Branche Abteilung',
    es: 'División de actividad',
  },
  unidade: {
    pt: 'Unidade',
    en: 'Unit',
    de: 'Einheit',
    es: 'Unidad',
  },
};