export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  novoPerfilUsuario: {
    pt: "Novo Perfil de Usuário",
    en: "New User Profile",
    de: "Neues Benutzerprofil",
    es: "Nuevo perfil de usuario",
  },
};
