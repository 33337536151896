import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User, LanguageSelector, Flag } from 'components/shared';
import * as S from './styles';
import { usePermissions, useTranslation } from 'hooks';
import { translations } from './translations';

interface IModule {
  icon: any;
  name: string;
  description: string;
  active: boolean;
  innerUrl?: string;
  url?: string;
}

const hostname = window.location.hostname;

const getEnvironment = () => {
  if (hostname === 'localhost') {
    return 'http://localhost:3000/base/';
  }

  if (hostname === 'evonik.webcol.systems') {
    return 'https://evonik.webcol.systems/';
  }

  if (hostname === 'hml-evonik.webcol.systems') {
    return 'https://hml-evonik.webcol.systems/';
  }

  return 'https://evonik.webcol.systems/';
};

const baseUrl = getEnvironment();

export const AsideMenu: React.FC = () => {
  const history = useHistory();
  const { checkUserPermission, checkModulePermission } = usePermissions();
  const { getTranslation } = useTranslation(translations);
  const isHomolog = hostname === 'hml-evonik.webcol.systems' ? true : false;
  const [controlTowerIsOpen, setControlTowerIsOpen] = useState(false);

  const modules: IModule[] = [
    // {
    //   icon: <S.IconControlTower />,
    //   name: getTranslation('controlTower'),
    //   description: getTranslation('descControlTower'),
    //   active: checkUserPermission('accessControlTower'),
    //   innerUrl: '/control-tower',
    // },
    {
      icon: <S.IconTracking />,
      name: getTranslation('Tracking'),
      description: getTranslation('descTracking'),
      active: checkUserPermission('accessTracking') || checkModulePermission(1),
      url: baseUrl + 'tracking/',
    },
    {
      icon: <S.IconSchedule />,
      name: getTranslation('agendamento'),
      description: getTranslation('descAgendamento'),
      active:
        checkUserPermission('accessScheduling') || checkModulePermission(2),

      url: baseUrl + 'agendamento/',
    },
    {
      icon: <S.IconCheckFreight />,
      name: getTranslation('CheckFretes'),
      description: getTranslation('descCheckFretes'),
      active:
        checkUserPermission('accessCheckFreights') || checkModulePermission(5),

      url: baseUrl + 'checkfrete/',
    },
    {
      icon: <S.IconRouter />,
      name: getTranslation('roterizador'),
      description: getTranslation('descRoterizador'),
      active: checkUserPermission('accessScripter') || checkModulePermission(3),

      url: baseUrl + 'roteirizador/',
    },
    {
      icon: <S.IconSettings />,
      name: getTranslation('configurador'),
      description: getTranslation('descConfigurador'),
      active: checkUserPermission('accessConfigurator'),

      innerUrl: '/settings',
    },
  ];

  const handleNavigation = useCallback(
    (module: IModule) => {
      if (module.active) {
        if (module.innerUrl) return history.push(module.innerUrl);
        if (module.url) return window.open(module.url);
      }
    },
    [history]
  );

  return (
    <S.Container>
      <S.Content>
        <S.LogoWrapper>
          <S.Logo onClick={() => history.push('/')} />
          <Flag />
        </S.LogoWrapper>
        <LanguageSelector />
        <User />
        <S.ButtonModule
          active={checkUserPermission('accessControlTower')}
          onClick={() => setControlTowerIsOpen(!controlTowerIsOpen)}
        >
          <S.ModuleTitle isHomolog={isHomolog}>
            <S.IconControlTower />
            {getTranslation('controlTower')}
          </S.ModuleTitle>
          <S.ModuleDescription>
            {getTranslation('descControlTower')}
          </S.ModuleDescription>
          <S.SubMenuModule active={controlTowerIsOpen}>
            <S.SubMenuModuleItem
              onClick={() => history.push('/control-tower/ghg-protocol')}
            >
              {getTranslation('controlTowerSubmenu', 'ghgProtocol')}
            </S.SubMenuModuleItem>
            <S.SubMenuModuleItem
              onClick={() => history.push('/control-tower/distribution')}
            >
              {getTranslation('controlTowerSubmenu', 'distribuicao')}
            </S.SubMenuModuleItem>
            <S.SubMenuModuleItem
              onClick={() => history.push('/control-tower/scheduling')}
            >
              {getTranslation('controlTowerSubmenu', 'agendamento')}
            </S.SubMenuModuleItem>
            <S.SubMenuModuleItem
              onClick={() => history.push('/control-tower/check-freights')}
            >
              {getTranslation('controlTowerSubmenu', 'checkFretes')}
            </S.SubMenuModuleItem>
            <S.SubMenuModuleItem
              onClick={() => history.push('/control-tower/billing')}
            >
              {getTranslation('controlTowerSubmenu', 'faturamento')}
            </S.SubMenuModuleItem>
            <S.SubMenuModuleItem
              onClick={() => history.push('/control-tower/financial-impact')}
            >
              {getTranslation('controlTowerSubmenu', 'financialImpact')}
            </S.SubMenuModuleItem>
          </S.SubMenuModule>
        </S.ButtonModule>
        {modules.map((module) => (
          <S.ButtonModule
            active={module.active}
            onClick={() => handleNavigation(module)}
            key={module.innerUrl || module.url}
          >
            <S.ModuleTitle isHomolog={isHomolog}>
              {module.icon}
              {module.name}
            </S.ModuleTitle>
            <S.ModuleDescription>{module.description}</S.ModuleDescription>
          </S.ButtonModule>
        ))}
      </S.Content>
    </S.Container>
  );
};
