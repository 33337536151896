export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novaDivision: {
    pt: 'Nova Unidade',
    en: 'New Unit',
    de: 'Neue Einheit',
    es: 'Nueva Unidad',
  },
};
