export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  novoAlerta: {
    pt: "Novo Alerta",
    en: "New Alert",
    de: "Neue Benachrichtigung",
    es: "Nueva alerta",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Zurück",
    es: "Regresa",
  },
  assunto: {
    pt: "Assunto",
    en: "Subject matter",
    de: "Betreff",
    es: "Tema en cuestion",
  },
  mensagem: {
    pt: "Mensagem",
    en: "Message",
    de: "Nachricht",
    es: "Mensaje",
  },
  destinatarios: {
    pt: "Destinatários",
    en: "Recipients",
    de: "Empfänger",
    es: "Destinatarios",
  },
  date: {
    pt: "Data",
    en: "Date",
    de: "Datum",
    es: "Fecha",
  },
  ReenviarAlerta: {
    pt: "Reenviar Alerta",
    en: "Resend Alert",
    de: "Alarm erneut senden",
    es: "Reenviar Alerta",
  },
};
