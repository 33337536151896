import styled from 'styled-components';
import { colors, fonts } from 'styles';

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  left: -30000px;
`;

export const Label = styled.div`
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: #000;
  margin-right: 8px;
`;

export const ToggleContainer = styled.div.attrs({
  className: 'toogle-container',
})`
  width: 34px;
  display: flex;
  align-items: center;
`;

export const ToggleTrack = styled.div.attrs({ className: 'track' })`
  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.red}66;
  position: relative;
`;

export const ToggleHandler = styled.div.attrs({ className: 'handler' })`
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${colors.red};
  margin-left: 0px;
  transition: margin-left 120ms linear;
`;

export const MainContainer = styled.div`
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const FieldContainer = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;

  align-items: center;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  height: 50px;
  padding: 0px 16px;

  cursor: pointer;
  > input:checked + div.toogle-container {
    div.handler {
      background-color: ${colors.darkGreen};
      margin-left: 20px;
    }
    div.track {
      background-color: ${colors.darkGreen}66;
    }
  }
`;
