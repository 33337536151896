import { call, put } from "redux-saga/effects";

import { apiGeneral, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateDocumentActions } from "store/ducks/documents";

export function* paginateDocumentRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      apiGeneral.get,
      `/document?${queryString}`
    );

    const { documents, pagination } = responseBody;
    yield put(PaginateDocumentActions.success(documents, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateDocumentActions.failure(errorMessage));
  }
}
