export const translations = {
  editarCategoria: {
    pt: 'Editar categoria de documentos',
    en: 'Edit document category',
    de: 'Kategorie von Dokumenten bearbeiten',
    es: 'Editar categoría de documentos',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Entfernen",
    es: "Retirar",
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  removerTitulo:{
    pt: 'Remover categoria	',
    en: 'Remove category',
    de: 'Kategorie entfernen	',
    es: 'Eliminar categoría',
  },
  removerDescricao: {
    pt: 'Ao remover esta categoria, ela deixará de ser exibida como opção de filtro durante a busca de documentos e também não estará mais disponível como opção de categoria durante o upload. Tem certeza de que deseja remover?	',
    en: 'By removing this category, it will no longer be displayed as a filter option during document search and will also not be available as a category option during upload. Are you sure you want to remove it?	',
    de: 'Durch Entfernen dieser Kategorie wird sie nicht mehr als Filteroption während der Suche nach Dokumenten angezeigt und steht auch nicht mehr als Kategorieoption während des Uploads zur Verfügung. Sind Sie sicher, dass Sie sie entfernen möchten?	',
    es: 'Al eliminar esta categoría, ya no se mostrará como opción de filtro durante la búsqueda de documentos y tampoco estará disponible como opción de categoría durante la carga. ¿Está seguro de que desea eliminarla?',
  },
};