export const translations = {
  codCentro: {
    pt: 'cod. Centro',
    en: 'cod. Center',
    de: 'Werk-Code',
    es: 'cod. Centro',
  },
  descCentro: {
    pt: 'Desc. Centro',
    en: 'Desc. Center',
    de: 'Beschr. Werk',
    es: 'Desc. Centrar',
  },
  deletar: {
    pt: 'Deletar',
    en: 'Delete',
    de: 'Löschen',
    es: 'Eliminar',
  },
};