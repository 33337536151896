import { call, put } from "redux-saga/effects";
import { apiGeneral, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { DeleteTransitTimeArActions } from "store/ducks/settings/transit-time-ar";

export function* deleteTransitTimeArRequest(action: any) {
  try {
    const { id, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      apiGeneral.delete,
      `/evonik-transit-times-ar/${id}`
    );

    const { data } = responseBody;
    yield put(DeleteTransitTimeArActions.success(data));
    notify("success", "Transito deletado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(DeleteTransitTimeArActions.failure(errorMessage));
  }
}
