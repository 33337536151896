export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Bundesstaat',
    es: 'Estado/Provincia',
  },
  estrangeiro: {
    pt: "Estrangeiro",
    en: "Foreign",
    de: "Ausland",
    es: "Extranjero",
  },
  nacional: {
    pt: "Nacional",
    en: "Nacional",
    de: "Inland",
    es: "Nacional"
  },
  provincy: {
    pt: "Província",
    en: "State",
    de: "Länder",
    es: "Província",
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Ort',
    es: 'Ciudad',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Schließen',
    es: 'Cerrar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  unidade: {
    pt: 'Unidade',
    en: 'Unit',
    de: 'Einheit',
    es: 'Unidad',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'Numero de Identificacion',
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
};