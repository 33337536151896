import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useTranslation, useValidation } from "hooks";
import { Modal } from "components/shared";
import { Select, Input } from "components/shared/Form";

import * as S from "./styles";
import { translations } from "./translations";

import {
  ListDocumentCategoryActions,
  ListDocumentCategoryState,
} from "store/ducks/settings/document-category";

interface IFilterDocumentProps {
  onFilter: Function;
}

export const FilterDocument: React.FC<IFilterDocumentProps> = ({
  onFilter,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const { getTranslation } = useTranslation(translations);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cleanShow, setCleanShow] = useState<boolean>(false);

  const { data: categoryOption } = useSelector<RootStateOrAny>(
    (state) => state.listDocumentCategory
  ) as ListDocumentCategoryState;

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        onFilter(data);

        setCleanShow(true);
        setModalOpen(false);
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [onFilter, handleFormErrors]
  );

  const handleFilterClean = useCallback(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("file_name", "");
      formRef.current.setFieldValue("description", "");
      formRef.current.setFieldValue("category_id", "");
      const clean = {
        file_name: "",
        description: "",
        category_id: "",
      };
      onFilter(clean);
    }

    setCleanShow(false);
    setModalOpen(false);
  }, [onFilter]);

  const renderButtonCleanFilter = () => {
    if (cleanShow) {
      return (
        <S.ButtonClean>
          <S.ButtonMini btStyle="dark" onClick={handleFilterClean}>
            {getTranslation("limparFiltro")}
          </S.ButtonMini>
        </S.ButtonClean>
      );
    }
  };

  const getCategories = useCallback(() => {
    dispatch(ListDocumentCategoryActions.request({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <>
      <Modal isOpen={modalOpen}>
        <S.ModalContainer>
          <S.Header>
            <S.IconFilter />
            <S.Title>{getTranslation("filtrar")}</S.Title>
          </S.Header>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <S.FormRow>
              <Input name="file_name" label={getTranslation("nome")} />
              <Input name="description" label={getTranslation("descricao")} />
            </S.FormRow>
            <S.FormRow>
              <Select
                name="category_id"
                label={getTranslation("categoria")}
                options={categoryOption}
              />

              <S.FormRow></S.FormRow>
            </S.FormRow>

            <S.ButtonsWrapper>
              <S.Button
                btStyle="cancel"
                onClick={() => setModalOpen(false)}
                type="button"
              >
                {getTranslation("fechar")}
              </S.Button>
              <S.Button
                btStyle="danger"
                type="button"
                onClick={handleFilterClean}
              >
                {getTranslation("limparFiltro")}
              </S.Button>
              <S.Button type="submit">{getTranslation("filtrar")}</S.Button>
            </S.ButtonsWrapper>
          </Form>
        </S.ModalContainer>
      </Modal>

      <S.ButtonContainer>
        {renderButtonCleanFilter()}
        <S.ButtonFilter onClick={() => setModalOpen(true)}>
          <S.IconFilter />
        </S.ButtonFilter>
      </S.ButtonContainer>
    </>
  );
};
