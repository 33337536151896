export const translations = {
  removerUnidade: {
    pt: 'Deseja realmente removendo essa unidade?',
    en: 'Do you really want to remove that drive?',
    de: 'Einheit löschen?',
    es: '¿De verdad quieres quitar esa unidad?',
  },
  editarDivision: {
    pt: 'Editar Division',
    en: 'Edit Division',
    de: 'Geschäftsbereich bearbeiten',
    es: 'Editar división',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
   cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Entfernen',
    es: 'Retirar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
};