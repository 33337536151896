export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  email: {
    pt: 'E-mail',
    en: 'E-mail',
    de: 'E-mail',
    es: 'Correo electrónico',
  },
};