import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import {
  IState,
  ISuccessAction,
  IFailureAction,
} from "interfaces/paginate-duck";
import { IZipcodeInternacional } from "interfaces/zipcode-internacional";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data", "pagination"],
    failure: ["error"],
    reset: [],
  },
  { prefix: "PAGINATE_ZIPCODE_INTERNACIONAL_" }
);

export interface PaginateZipcodeInternacionalState extends IState {
  data: IZipcodeInternacional[] | Record<string, any>[];
}

const INITIAL_STATE: PaginateZipcodeInternacionalState = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
};

const request = (state: PaginateZipcodeInternacionalState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (
  state: PaginateZipcodeInternacionalState,
  action: ISuccessAction
) =>
  update(state, {
    data: { $set: action.data },
    pagination: { $set: action.pagination },
    loading: { $set: false },
  });

const failure = (
  state: PaginateZipcodeInternacionalState,
  action: IFailureAction
) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const paginateZipcodeInternacional = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const PaginateZipcodeInternacionalTypes = Types;
export const PaginateZipcodeInternacionalActions = Creators;
