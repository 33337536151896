export const translations = {
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  modulos: {
    pt: 'Modulos',
    en: 'Modules',
    de: 'Module',
    es: 'Modulos',
  },
};