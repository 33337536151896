import { call, put } from "redux-saga/effects";

import { apiGeneral, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginateTransitsArActions } from "store/ducks/settings/transit-time-ar";

export function* paginateTransitTimesRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      apiGeneral.get,
      `/evonik-transit-times-ar?${queryString}`
    );

    const { data, pagination } = responseBody;
    yield put(PaginateTransitsArActions.success(data, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginateTransitsArActions.failure(errorMessage));
  }
}
