export const translations = {
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Werk',
    es: 'Centrar',
  },
  peso: {
    pt: 'Peso (Kg)',
    en: 'Weight (Kg)',
    de: 'Gewicht (Kg)',
    es: 'Peso (Kg)',
  },
  duracao: {
    pt: 'Duração (minutos)',
    en: 'Duration (minutes)',
    de: 'Dauer (Minuten)',
    es: 'Duración (minutos)',
  },
  palete: {
    pt: 'Palete',
    en: 'Pallet',
    de: 'Palette',
    es: 'Paleta',
  },
  veiculo: {
    pt: 'Tipo de veículo',
    en: 'Vehicle type',
    de: 'Fahrzeugtyp',
    es: 'Tipo de vehiculo',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
};