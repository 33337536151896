export const translations = {
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Name",
    es: "Nombre",
  },
  grupo: {
    pt: "Grupo",
    en: "Group",
    de: "Gruppe",
    es: "Grupo",
  },
  modulo: {
    pt: "Módulo",
    en: "Module",
    de: "Modul",
    es: "Módulo",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
};
