export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  editarPerfilUsuario: {
    pt: "Editar Perfil de Usuário",
    en: "Edit User Profile",
    de: "Benutzerprofil bearbeiten",
    es: "Editar perfil de usuario",
  },
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Name",
    es: "Nombre",
  },
  modulo: {
    pt: "Módulo",
    en: "Module",
    de: "Modul",
    es: "Módulo",
  },
  permissoesPerfil: {
    pt: "Permissões de perfil",
    en: "Profile permissions",
    de: "Profilberechtigungen",
    es: "Permisos de perfil",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Löschen",
    es: "Retirar",
  },
  salvar: {
    pt: "Salvar",
    en: "Save",
    de: "Speichern",
    es: "Ahorrar",
  },
  removerTitulo: {
    pt: "Remover Perfil de Usuário",
    en: "Remove User Profile",
    de: "Benutzerprofil entfernen",
    es: "Eliminar perfil de usuario",
  },
  removerDesc: {
    pt: "Deseja realmente remover esse perfil?",
    en: "Do you really want to remove this profile?",
    de: "Möchten Sie dieses Profil wirklich entfernen?",
    es: "¿Realmente deseas eliminar este perfil?",
  },
};
