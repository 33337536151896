import React, { useRef, useCallback, useState, useEffect } from 'react';

import * as S from './styles';
import { MainContainer } from 'components/shared';

export const FinancialImpact: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleClick = useCallback(() => {
    if (ref.current) {
      if (window.document.fullscreenElement) {
        if (window.document.exitFullscreen) {
          window.document.exitFullscreen();
        }
      } else {
        ref.current.requestFullscreen();
      }
    }
  }, []);

  const handleKeyDown = useCallback(() => {
    setIsFullscreen((v) => !v);
  }, []);

  useEffect(() => {
    if (window.document) {
      window.document.addEventListener('fullscreenchange', handleKeyDown);
    }

    return () => {
      window.document.removeEventListener('fullscreenchange', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <MainContainer>
      <S.Container ref={ref}>
        <S.Button onClick={handleClick}>
          {isFullscreen ? <S.IconExitFullscreen /> : <S.IconFullscreen />}
        </S.Button>
        <S.Iframe
          width="100%"
          height="100%"
          src="https://analytics.zoho.com/open-view/1432280000129157215/c721da11e21e7ecb7483db840be81ad7"
        />
      </S.Container>
    </MainContainer>
  );
};
