import { createReducer, createActions } from 'reduxsauce';
import update from 'immutability-helper';
import { IState, ISuccessAction, IFailureAction } from 'interfaces/update-duck';

const { Types, Creators } = createActions(
  {
    request: ['id', 'onSuccess', 'onFailure'],
    success: ['data'],
    failure: ['error'],
    reset: [],
  },
  { prefix: 'DELETE_CLIENT_CODE_' }
);

export interface DeleteClientCodeState extends IState {}

const INITIAL_STATE: DeleteClientCodeState = {
  loading: false,
  error: null,
};

const request = (state: DeleteClientCodeState) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state: DeleteClientCodeState, action: ISuccessAction) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (state: DeleteClientCodeState, action: IFailureAction) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const deleteClientCode = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const DeleteClientCodeTypes = Types;
export const DeleteClientCodeActions = Creators;
