export const translations = {
  selecione: {
    pt: "Selecione...",
    en: "Select...",
    de: "auswählen...",
    es: "Seleccione...",
  },
  obrigatorio: {
    pt: "Obrigtório",
    en: "Mandatory",
    de: "Verpflichtend",
    es: "Obligatorio",
  },
  incompatibilities: {
    pt: "Incompatibilidades",
    en: "Incompatibilities",
    de: "Inkompatibilitäten",
    es: "Incompatibilidades",
  },
  incompatibility: {
    pt: "Incompatibilidade",
    en: "Incompatibility",
    de: "Inkompatibilität",
    es: "Incompatibilidad",
  },
  excess: {
    pt: "Excessões",
    en: "Exceptions",
    de: "Ausnahmen",
    es: "Excepciones",
  },
  excessao: {
    pt: "Excessão",
    en: "Exception",
    de: "Ausnahme",
    es: "Excepción",
  },
  adicionar: {
    pt: "Adicionar",
    en: "Add",
    de: "Addieren",
    es: "Agregar",
  },
};
