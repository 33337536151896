import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";

import {
  PaginateZipcodeInternacionalActions,
  PaginateZipcodeInternacionalState,
} from "store/ducks/settings/zipcode-internacional";

import { SelectedFilterActions, SelectedFilterState } from "store/ducks/filter";

import * as S from "./styles";
import { MainContainer, Paginator, MenuSelector } from "components/shared";
import { GridZipcodeInternacional } from "components/settings";

export const ListZipcodeInternacional: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const { checkUserPermission } = usePermissions();

  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
  });

  const { data, loading, pagination } = useSelector<
    RootStateOrAny,
    PaginateZipcodeInternacionalState
  >((state) => state.paginateZipcodeInternacional);

  const { data: dataFilter } = useSelector<RootStateOrAny>(
    (state) => state.selectedFilter
  ) as SelectedFilterState;

  const handleSubmitFilter = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        ...value,
        page: 1,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const handlePageChange = useCallback(
    (value) => {
      const data = {
        ...dataFilter,
        page: value,
      };
      dispatch(SelectedFilterActions.success(data));
      setQuery((state) => ({ ...state, ...data }));
    },
    [setQuery, dispatch, dataFilter]
  );

  const getData = useCallback(() => {
	console.log('teste');
	
    dispatch(PaginateZipcodeInternacionalActions.request({ ...query, ...dataFilter }));
  }, [dispatch, query, dataFilter]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configurações")} <MenuSelector page="zipcode-internacional" />
          {loading && <S.LoadingPage />}
        </h1>
        <S.HeaderButtons>
          {checkUserPermission("insertZipcodeInternacional") && (
            <S.ButtonMini onClick={() => history.push("/settings/zipcode-internacional/new")}>
              {getTranslation("novoZipcode")}
            </S.ButtonMini>
          )}
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <GridZipcodeInternacional zipcodes={data} />
        <Paginator
          pagination={pagination}
          onPageChange={handlePageChange}
          loading={loading}
        />
      </S.PageContent>
    </MainContainer>
  );
};
