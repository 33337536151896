import styled from "styled-components";
import { colors } from "styles";
export { Button, Loading, ButtonMini, FormRow } from "styles/styled-components";

export const Container = styled.div`
  display: flex;
  column-gap: 16px;
`;
export const ModalContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 560px;
  max-height: 90vh;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 40px;
  margin: 32px 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.line};
    border-radius: 3px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px ${colors.line} solid;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
`;

export const Text = styled.article`
  width: 100%;
  font-size: 16px;
  color: ${colors.dark};
  margin-bottom: 48px;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-left: none;
  border-right: none;

  button:first-child {
    margin-right: 16px;
  }
`;
