import { call, put } from 'redux-saga/effects';
import { apiGeneral, notify } from 'services';
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from 'utils';
import { UpdateClientCodeActions } from 'store/ducks/settings/client-codes';

export function* updateClientCodeRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;

    const { data: responseBody } = yield call(
      apiGeneral.put,
      `/client-codes/update/${id}`,
      putData
    );  

    const { data } = responseBody;
    yield put(UpdateClientCodeActions.success(data));
    notify('success', 'Código SAP alterado com sucesso');
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify('error', errorMessage);
    yield put(UpdateClientCodeActions.failure(errorMessage));
  }
}
