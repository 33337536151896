export const translations = {
  limparFiltro: {
    pt: "Limpar Filtro",
    en: "Clear Filter",
    de: "Klarfilter",
    es: "Filtro limpio",
  },
  obrigatorio: {
    pt: "Obrigtório",
    en: "Mandatory",
    de: "Verpflichtend",
    es: "Obligatorio",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  filtrar: {
    pt: "Filtrar",
    en: "Filter",
    de: "Filter",
    es: "Filtrar",
  },

  selecione: {
    pt: "Selecione...",
    en: "Select...",
    de: "Auswählen...",
    es: "Seleccione...",
  },
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  cadastrar: {
    pt: "Cadastrar",
    en: "Register",
    de: "Katastern",
    es: "Registrar",
  },

  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },

  zipcode: {
    pt: "CEP (Zipcode)",
    en: "Zipcode",
    de: "Postleitzahl",
    es: "Código postal",
  },
  city: {
    pt: "Cidade",
    en: "City",
    de: "Stadt",
    es: "Ciudad",
  },
  state: {
    pt: "Estado",
    en: "State",
    de: "Bundesland",
    es: "Estado",
  },
  neighborhood: {
    pt: "Bairro",
    en: "Neighborhood",
    de: "Nachbarschaft",
    es: "Barrio",
  },
  street: {
    pt: "Rua",
    en: "Street",
    de: "Straße",
    es: "Calle",
  },
  latitude: {
    pt: "Latitude",
    en: "Latitude",
    de: "Breitengrad",
    es: "latitud",
  },
  longitude: {
    pt: "Longitude",
    en: "Longitude",
    de: "Längengrad",
    es: "longitud",
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
};
