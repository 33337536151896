export const translations = {
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Name",
    es: "Nombre",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  filtrar: {
    pt: "Filtrar",
    en: "Filter",
    de: "Filtern",
    es: "Filtrar",
  },
  limparFiltro: {
    pt: "Limpar filtro",
    en: "Clear filter",
    de: "Filter löschen",
    es: "Limpiar filtro",
  },
  grupo: {
    pt: "Grupo",
    en: "Group",
    de: "Gruppe",
    es: "Grupo",
  },
};
