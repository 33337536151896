export const translations = {
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'id',
    es: 'Id',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
  email: {
    pt: 'E-mail',
    en: 'E-mail',
    de: 'E-mail',
    es: 'Correo electrónico',
  },
  tipo: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Typ',
    es: 'Escribe',
  },
  ultimoAcesso: {
    pt: 'Último acesso',
    en: 'Last access',
    de: 'Letzter Zugriff',
    es: 'Último acceso',
  }
};