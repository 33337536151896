import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { IDocuments } from "interfaces/documents";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";

interface IGridDocumentProps {
  docs: IDocuments[] | Record<string, any>[];
  handleDownload: Function;
}

interface itensProps {
  doc: IDocuments | Record<string, any>;
  onDownload: () => void;
}

const Item: React.FC<itensProps> = ({ doc, onDownload }) => {
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const { hasEveryPermission } = usePermissions();

  const isAdmin = hasEveryPermission(["Administrador"]);
  return (
    <S.ItemContainer
      onClick={() => (isAdmin ? history.push(`/docs/${doc.id} `) : {})}
    >
      <S.ItemContent>
        <S.ItemValue>{doc.file_name || "--"}</S.ItemValue>
        <S.ItemValue>{doc.description || "--"}</S.ItemValue>
        <S.ItemValue>{doc.category.name || "--"}</S.ItemValue>

        <S.ButtonContainer>
          <S.IconDownload
            onClick={(event) => {
              event.stopPropagation();
              onDownload();
            }}
            title={getTranslation("download")}
          />
          {isAdmin && <S.IconDetail />}
        </S.ButtonContainer>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridDocument: React.FC<IGridDocumentProps> = ({
  docs = [],
  handleDownload,
}) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation("nome")}</S.Label>
        <S.Label>{getTranslation("descricao")}</S.Label>
        <S.Label>{getTranslation("categoria")}</S.Label>
      </S.Header>
      {docs.length > 0 &&
        docs.map((item) => (
          <Item
            doc={item}
            key={item.id}
            onDownload={() => handleDownload(item.id)}
          />
        ))}
    </S.Container>
  );
};
