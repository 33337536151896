export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoUsuario: {
    pt: 'Novo usuário',
    en: 'New user',
    de: 'Neuer Benutzer',
    es: 'Nuevo usuario',
  },
};