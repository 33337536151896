export const translations = {
  controlTower: {
    pt: 'Control Tower',
    en: 'Control Tower',
    de: 'Control Tower',
    es: 'Control Tower',
  },
  descControlTower: {
    pt: 'Eficiência na operação logística.',
    en: 'Efficiency in logistics operations.',
    de: 'Effizienz in der Logistik.',
    es: 'Eficiencia en operaciones logísticas.',
  },
  controlTowerSubmenu: {
    ghgProtocol: {
      pt: 'GHG Protocol',
      en: 'GHG Protocol',
      de: 'GHG Protocol',
      es: 'GHG Protocol',
    },
    distribuicao: {
      pt: 'Distribuição',
      en: 'Distribution',
      de: 'Verteilung',
      es: 'Distribución',
    },
    agendamento: {
      pt: 'Agendamento',
      en: 'Scheduling',
      de: 'Terminplanung',
      es: 'Planificación',
    },
    checkFretes: {
      pt: 'CheckFretes',
      en: 'CheckFreights',
      de: 'CheckFretes',
      es: 'Comprobar fletes',
    },
    faturamento: {
      pt: 'Faturamento',
      en: 'Billing',
      de: 'Fakturierung',
      es: 'Facturación',
    },
    financialImpact: {
      pt: 'Impacto Financeiro',
      en: 'Financial Impact',
      de: 'Finanzielle Auswirkungen',
      es: 'Impacto financiero',
    },
  },
  roterizador: {
    pt: 'Roterizador',
    en: 'Scripter',
    de: 'Routenplaner',
    es: 'Itinerarios',
  },
  descRoterizador: {
    pt: 'Otimização na geração de cargas.',
    en: 'Load generation optimization.',
    de: 'Optimierung der Erstellung der Ladung.',
    es: 'Optimización en la programación de las cargas.',
  },
  agendamento: {
    pt: 'Agendamento',
    en: 'Scheduling',
    de: 'Terminplanung',
    es: 'Planificación',
  },
  descAgendamento: {
    pt: 'Agendamento e gestão das cargas roterizadas.',
    en: 'Scheduling and management of routed loads.',
    de: 'Terminplanung und Verwaltung von Routing-Ladungen.',
    es: 'Programación y gestión de cargas enrutadas.',
  },
  Tracking: {
    pt: 'Tracking',
    en: 'Tracking',
    de: 'Tracking',
    es: 'Seguimiento',
  },
  descTracking: {
    pt: 'Monitoramento e gestão de entregas em tempo real.',
    en: 'Monitoring and management of deliveries in real time.',
    de: 'Überwachung und Verwaltung von Lieferungen in Echtzeit.',
    es: 'Seguimiento y gestión de entregas en tiempo real.',
  },
  CheckFretes: {
    pt: 'CheckFretes',
    en: 'CheckFreights',
    de: 'CheckFretes',
    es: 'Comprobar fletes',
  },
  descCheckFretes: {
    pt: 'Gestão total dos fretes por transportadora.',
    en: 'Total management of freight by carrier.',
    de: 'Gesamtverwaltung der Frachten pro Spediteur.',
    es: 'Gestión total del flete por transportista.',
  },
  configurador: {
    pt: 'Configurador',
    en: 'Settings',
    de: 'Konfigurationen',
    es: 'Configuraciones',
  },
  descConfigurador: {
    pt: 'Gerenciameto de acessos e parametrização do sistema.',
    en: 'Management of access and parameterization of the system.',
    de: 'Zugangsverwaltung und Systemparametrisierung.',
    es: 'Gestión de accesos y parametrización del sistema.',
  },
  relatorios: {
    pt: 'Origem NFe/CTe',
    en: 'Origin NFe/CTe',
    de: 'Herkunft NFe/CTe',
    es: 'Origen NFe/CTe',
  },
  descRelatorios: {
    pt: 'Origem de importação de documentos.',
    en: 'Origin of document import.',
    de: 'Herkunft des Dokumentenimports.',
    es: 'Origen de la importación de documentos.',
  },
};
