export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'Numero de Identificacion',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Ort',
    es: 'Ciudad',
  },
};