import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { IDocumentCategory } from "interfaces/document-category";
import { useTranslation, usePermissions } from "hooks";
import { translations } from "./translations";

interface IGridDocumentCategoryProps {
  categories: IDocumentCategory[] | Record<string, any>[];
}

interface itensProps {
  category: IDocumentCategory | Record<string, any>;
}

const Item: React.FC<itensProps> = ({ category }) => {
  const history = useHistory();
  const { checkUserPermission } = usePermissions();
  const havePermission = checkUserPermission("editDocCategory");
  return (
    <S.ItemContainer
      onClick={() =>
        havePermission &&
        history.push(`/settings/document-category/${category.id}`)
      }
    >
      <S.ItemContent pointer={havePermission}>
        <S.ItemValue>{category.id || "--"}</S.ItemValue>
        <S.ItemValue>{category.name || "--"}</S.ItemValue>
        <S.ItemValue>{category.description || "--"}</S.ItemValue>
        {havePermission && (
          <S.ButtonDetail>
            <S.IconDetail />
          </S.ButtonDetail>
        )}
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridDocumentCategory: React.FC<IGridDocumentCategoryProps> = ({
  categories = [],
}) => {
  const { getTranslation } = useTranslation(translations);
  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation("id")}</S.Label>
        <S.Label>{getTranslation("nome")}</S.Label>
        <S.Label>{getTranslation("descricao")}</S.Label>
      </S.Header>
      {categories.length > 0 &&
        categories.map((item) => <Item category={item} key={item.id} />)}
    </S.Container>
  );
};
