import React, { useCallback } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { AuthState } from 'store/ducks/auth';
import * as S from './styles';

export const Flag: React.FC = () => {
  const { data: userData } = useSelector<RootStateOrAny>(
    (state) => state.auth
  ) as AuthState;

  const renderFlag = useCallback(() => {
    const country = userData?.country[0].name;

    switch (country) {
      case 'BRASIL':
        return <span className="fi fi-br" />;
      case 'ARGENTINA':
        return <span className="fi fi-ar" />;
      case 'CHILE':
        return <span className="fi fi-cl" />;
      case 'COLOMBIA':
        return <span className="fi fi-co" />;
      case 'EQUADOR':
        return <span className="fi fi-ec" />;
      case 'GUATEMALA':
        return <span className="fi fi-gt" />;
      case 'PERU':
        return <span className="fi fi-pe" />;
      default:
        return <span className="fi fi-br" />;
    }
  }, [userData]);

  return <S.Container>{renderFlag()}</S.Container>;
};
