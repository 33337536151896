export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },

  novoZipcode: {
    pt: "Novo zipcode",
    en: "New zipcode",
    de: "Neue Postleitzahl",
    es: "Nuevo código postal",
  },
  zipcodeInternacional: {
    pt: "zipcode internacional",
    en: "international zipcode",
    de: "internationale Postleitzahl",
    es: "código postal internacional",
  },
};
