import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import { ListDocument, NewDocument, EditDocument } from "pages/Document";

interface Props {}

export const DocumentRoutes: React.FC<Props> = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/docs" component={ListDocument} />
      <PrivateRoute
        exact
        path="/docs/new"
        component={NewDocument}
        hasEveryPermission={["Administrador"]}
      />
      <PrivateRoute
        exact
        path="/docs/:id"
        component={EditDocument}
        hasEveryPermission={["Administrador"]}
      />
    </Switch>
  );
};
