import React from "react";
import { MainContainer, MenuSelector } from "components/shared";
import { useTranslation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";

export const SettingMain: React.FC = () => {
  const { getTranslation } = useTranslation(translations);
  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")} <MenuSelector />
        </h1>
      </S.PageHeader>
      <S.PageContent></S.PageContent>
    </MainContainer>
  );
};
