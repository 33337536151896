import { call, put } from "redux-saga/effects";
import { apiGeneral, notify } from "services";
import { IUpdateRequest } from "interfaces/update-duck";
import { requestErrorHandler } from "utils";
import { UpdateDocumentActions } from "store/ducks/documents";

export function* updateDocumentRequest(action: any) {
  try {
    const { id, putData, onSuccess } = action as IUpdateRequest;    
    const { data: responseBody } = yield call(
      apiGeneral.put,
      `/document/${id}`,
      putData
    );

    const { data } = responseBody;
    yield put(UpdateDocumentActions.success(data));
    notify("success", "Documento atualizado com sucesso");
    if (onSuccess) onSuccess();
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(UpdateDocumentActions.failure(errorMessage));
  }
}
