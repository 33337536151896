import styled, { css } from "styled-components";
import { CheckmarkSquare2Outline } from "@styled-icons/evaicons-outline/CheckmarkSquare2Outline";
import { SquareOutline } from "@styled-icons/evaicons-outline/SquareOutline";
import { fonts, colorScheme } from "styles";

export { FieldError, FieldLabel } from "styles/styled-components";

export const FieldContainer = styled.div<{ hide?: boolean }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-bottom: 16px;
  position: relative;

  &:not(:last-child) {
    margin-right: 16px;
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      width: 0;
      height: 0;
      &:not(:last-child) {
        margin-right: 0px;
      }
    `}
`;

export const OptionsContainer = styled.div<{ direction?: "row" | "column" }>`
  ${({ direction = "row" }) => css`
    flex-direction: ${direction};
    row-gap: ${direction === "row" ? "12px" : ""};
  `};
  display: flex;
  padding: 12px;
  border-radius: 4px;
  flex-wrap: wrap;
  min-height: 50px;
  columns: 1;
  column-gap: 24px;

  @media screen and (max-width: 414px) {
    columns: 1;
  }
`;

export const Option = styled.label<{fixWidth?:string}>`
  ${({ fixWidth }) =>
    fixWidth &&
    css`
      width: ${fixWidth};
    `}
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: ${fonts.GilroyRegular};
  cursor: pointer;
`;

export const Icons = styled.div.attrs({ className: "icons" })``;

export const Input = styled.input`
  appearance: none;
  &:checked + .icons > .checked {
    display: initial;
  }
  &:not(:checked) + .icons > .unchecked {
    display: initial;
  }
`;

export const CheckedIcon = styled(CheckmarkSquare2Outline).attrs({
  size: 24,
  className: "checked",
})`
  color: ${colorScheme.primary};
  margin-right: 10px;
  display: none;
`;

export const UncheckedIcon = styled(SquareOutline).attrs({
  size: 24,
  className: "unchecked",
})`
  color: #606060;
  margin-right: 10px;
  display: none;
`;
