import styled from 'styled-components';
import qrCodeSara from 'assets/images/qrcode-sara.svg';
import qrCodeApp from 'assets/images/qrcode-app.svg';
import avatarSara from 'assets/images/sara.png';
import avatarApp from 'assets/images/app.svg';
import avatarMotorista from 'assets/images/avatar-motorista.png';
import { colors, fonts } from 'styles';
import { File } from 'styles/styled-components';
import logoTms from 'assets/images/tms.png';
export { ButtonMiniVariant } from 'styles/styled-components';

export const Container = styled.main`
  display: flex;
  width: 100%;
  max-width: 1920px;
  height: 100vh;
  background: linear-gradient(
    112.46deg,
    ${colors.deepPurple} 3.3%,
    ${colors.deepPurple2} 100.38%
  );
  margin: 0 auto;
  position: relative;
`;

export const Content = styled.section`
  width: 100vw;
  height: 100vh;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .siteBlindado {
    position: absolute;
    bottom: 48px;
    left: 48px;
    z-index: 1;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  column-gap: 64px;
  width: max-content;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 6px;
  padding: 56px 32px 32px 32px;
  margin-bottom: 32px;
  box-shadow: 0px 5px 30px rgba(77, 14, 65, 0.35);
`;

export const QRCodeSara = styled.img.attrs({ src: qrCodeSara })`
  width: 160px;
`;

export const QRCodeApp = styled.img.attrs({ src: qrCodeApp })`
  width: 160px;
`;

export const AvatarSara = styled.img.attrs({ src: avatarSara })`
  width: 80px;
  margin-top: -90px;
`;

export const AvatarApp = styled.img.attrs({ src: avatarApp })`
  width: 80px;
  margin-top: -90px;
`;
export const AvatarMotorista = styled.img.attrs({ src: avatarMotorista })`
  width: 80px;
  margin-top: -90px;
`;

export const Name = styled.span`
  display: block;
  padding: 16px 0;
  font-size: 14px;
  font-family: ${fonts.GilroyBold};
  color: ${colors.dark};
`;

export const Text = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  text-align: center;
  max-width: 224px;
`;

export const BtnFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 48px;
  right: 48px;
  z-index: 1;
`;

export const IconFile = styled(File).attrs({ size: 20 })`
  margin-right: 8px;
  color: ${colors.deepPurple};
`;

export const TMS = styled.img.attrs({ src: logoTms })`
  position: absolute;
  bottom: 48px;
  left: 48px;

  @media screen and (max-width: 1366px) {
    bottom: 24px;
    left: 24px;
  }
`;
