export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  zipcode: {
    pt: "CEP (Zipcode)",
    en: "Zipcode",
    de: "Postleitzahl",
    es: "Código postal",
  },
  city: {
    pt: "Cidade",
    en: "City",
    de: "Stadt",
    es: "Ciudad",
  },
  state: {
    pt: "Estado",
    en: "State",
    de: "Bundesland",
    es: "Estado",
  },
  neighborhood: {
    pt: "Bairro",
    en: "Neighborhood",
    de: "Nachbarschaft",
    es: "Barrio",
  },
  street: {
    pt: "Rua",
    en: "Street",
    de: "Straße",
    es: "calle",
  },
  latitude: {
    pt: "Latitude",
    en: "Latitude",
    de: "Breitengrad",
    es: "latitud",
  },
  longitude: {
    pt: "Longitude",
    en: "Longitude",
    de: "Längengrad",
    es: "longitud",
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
};