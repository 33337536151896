export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Bundesstaat',
    es: 'Estado/Provincia',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Ort',
    es: 'Ciudad',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Schließen',
    es: 'Cerrar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  cnpj: {
    pt: 'CNPJ/CPF',
    en: 'CNPJ/CPF',
    de: 'CNPJ/CPF',
    es: 'Numero de Identificacion',
  },
  segmentacao: {
    pt: 'Segmentação de clientes',
    en: 'Customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Firmenname',
    es: 'Razón Social',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Wählen Sie...',
    es: 'Seleccione...',
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
  IdExterior: {
    pt: 'Id Exterior',
    en: 'External ID',
    de: 'Externe ID',
    es: 'ID externa',
  },
};