import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { User } from "interfaces/user";
import { useTranslation, usePermissions } from "hooks";

import { translations } from "./translations";
import { niceDate } from "utils";
import { RootStateOrAny, useSelector } from "react-redux";
import { LanguageState } from "store/ducks/language";

interface IGridUsersProps {
  users: User[] | Record<string, any>[];
  onImpersonate: Function;
}

interface IUserProps {
  user: User | Record<string, any>;
  language: string;
  action: (email: string) => void;
}

const Item: React.FC<IUserProps> = ({ user, language, action }) => {
  const history = useHistory();
  const { checkUserPermission } = usePermissions();
  const havePermission = checkUserPermission("editUser");
  const impersonatePermission = checkUserPermission("impersonate");
  return (
    <S.ItemContainer
      onClick={() =>
        havePermission && history.push(`/settings/user/${user.id}`)
      }
    >
      <S.ItemContent pointer={havePermission}>
        <S.ItemValue>{user.id || "--"}</S.ItemValue>
        <S.ItemValue>
          {user.roles.length ? user.roles[0].name : "--"}
        </S.ItemValue>
        <S.ItemValue>{user.name || "--"}</S.ItemValue>
        <S.ItemValue>{user.email || "--"}</S.ItemValue>
        <S.ItemValue>
          {user.last_access
            ? niceDate({ dateString: user.last_access, language })
            : "--"}
        </S.ItemValue>
        <S.ButtonDetail>
          {impersonatePermission && (
            <S.IconLogin
              onClick={(event) => {
                event.stopPropagation();
                action(user.email);
              }}
            />
          )}
          {havePermission && <S.IconDetail />}
        </S.ButtonDetail>
      </S.ItemContent>
    </S.ItemContainer>
  );
};

export const GridUsers: React.FC<IGridUsersProps> = ({
  users = [],
  onImpersonate,
}) => {
  const { getTranslation } = useTranslation(translations);
  const { language } = useSelector<RootStateOrAny, LanguageState>(
    (state) => state.language
  );

  const getEmailUser = useCallback(
    (email) => {
      onImpersonate(email);
    },
    [onImpersonate]
  );

  return (
    <S.Container>
      <S.Header>
        <S.Label>{getTranslation("id")}</S.Label>
        <S.Label>{getTranslation("tipo")}</S.Label>
        <S.Label>{getTranslation("nome")}</S.Label>
        <S.Label>{getTranslation("email")}</S.Label>
        <S.Label>{getTranslation("ultimoAcesso")}</S.Label>
      </S.Header>
      {users.length > 0 &&
        users.map((user) => (
          <Item
            user={user}
            key={user.id}
            language={language}
            action={getEmailUser}
          />
        ))}
    </S.Container>
  );
};
