import { call, put } from "redux-saga/effects";

import { apiGeneral, queryBuilder, notify } from "services";
import { IPaginateRequest } from "interfaces/paginate-duck";
import { requestErrorHandler } from "utils";
import { PaginatePermissionsActions } from "store/ducks/settings/permissions";

export function* paginatePermissionsRequest(action: any) {
  try {
    const { query = {} } = action as IPaginateRequest;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      apiGeneral.get,
      `/permissions?${queryString}`
    );

    const { permissions, pagination } = responseBody;
    
    yield put(PaginatePermissionsActions.success(permissions, pagination));
  } catch (error) {
    const { errorMessage } = requestErrorHandler(error);
    notify("error", errorMessage);
    yield put(PaginatePermissionsActions.failure(errorMessage));
  }
}
