import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import type { IDocuments } from "interfaces/documents";

const { Types, Creators } = createActions(
  {
    request: ["postData", "onSuccess", "onFailure"],
    success: [],
    failure: ["errorMessage"],
    reset: [],
  },
  {
    prefix: "CREATE_DOCUMENT_",
  }
);

export interface CreateDocumentState {
  loading: boolean;
  errorMessage: string | null;
}

export interface CreateDocumentRequestAction {
  postData: IDocuments;
  onSuccess?: (id: string) => void;
  onFailure?: () => void;
}

interface CreateDocumentFailureAction {
  errorMessage: string;
  validationErrors?: any;
}

const INITIAL_STATE: CreateDocumentState = {
  loading: false,
  errorMessage: null,
};

const request = (state: CreateDocumentState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state: CreateDocumentState) =>
  update(state, {
    loading: { $set: false },
  });

const failure = (
  state: CreateDocumentState,
  action: CreateDocumentFailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const reset = () => INITIAL_STATE;

export const createDocument = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateDocumentTypes = Types;
export const CreateDocumentActions = Creators;
