export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoCliente: {
    pt: 'Novo cliente',
    en: 'New customer',
    de: 'Neuer Kunde',
    es: 'Nuevo cliente',
  },
};